import { X } from "lucide-react";
import { ElementType } from "react";

export interface BadgeProps {
  onDelete?: () => void;
  Icon: ElementType;
  text: string;
  className?: string;
  overwriteClassName?: boolean;
  deleteClassName?: string;
  contentClassName?: string;
  deleteIconColor?: string;
}

const Badge = ({
  onDelete,
  Icon,
  text,
  className = "",
  overwriteClassName,
  deleteClassName = "",
  contentClassName = "",
  deleteIconColor = "",
}: BadgeProps) => {
  return (
    <div className={overwriteClassName ? className : `p-2 rounded-sm flex gap-2 bg-neutral-op-8 w-fit ${className}`}>
      {onDelete ? (
        <div
          className={`flex justify-center items-center cursor-pointer ${deleteClassName}`}
          onClick={onDelete}
        >
          <X color={deleteIconColor || "var(--danger)"} size="18px" />
        </div>
      ) : null}
      <div className={`flex gap-1 text-sm items-center justify-center ${contentClassName}`}>
        <Icon size="14px" />
        <p>{text}</p>
      </div>
    </div>
  );
};

export default Badge;