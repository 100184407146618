import { LucideProps } from 'lucide-react';
import { Tooltip } from 'react-tooltip';

interface CopyButtonProps extends LucideProps {
  control: {
    tooltipId: string;
    icon: React.ComponentType<LucideProps>;
    message: string;
  };
}

export const CopyButton = ({ control: { tooltipId, icon: Icon, message }, ...props }: CopyButtonProps) => (
  <>
    <Icon data-tooltip-id={tooltipId} strokeWidth={1.5} {...props} />
    <Tooltip id={tooltipId}>{message}</Tooltip>
  </>
);
