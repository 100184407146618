import usePrivateAxios from "@/common/api/usePrivateAxios";
import { CardDetailsData, CardDetailsResponse } from "@/common/types/cards/details.type";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

export const useCardDetails: (id?: string, product_id?: string) => UseQueryResult<CardDetailsData, unknown> = (id?: string, product_id?: string) => {
  const privateAxios = usePrivateAxios();

  return useQuery<CardDetailsResponse, unknown, CardDetailsData>({
    enabled: !!(id),
    retry: false,
    queryKey: ['cardDetails', id],
    queryFn: () => privateAxios.get(`/client/cards/${id}/details`, { params: { product_id } }),
    select: (data) => data?.data?.data, 
  });
};
