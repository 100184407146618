import Box from '@/common/components/Box';
import { options as unityForQuantityOptions } from '@/common/constants/unitForQuantityOptions.contant';
import { Trash } from 'lucide-react';
import { Controller, FieldArrayWithId, useFormContext } from 'react-hook-form';
import { ConsumptionValidationSchema } from '../schemas/create.schema';
import { CurrencyTextField } from '@/common/components/CurrencyTextField';
import { UnitTextField } from '@/common/components/UnitTextField';

interface ProductItemProps {
  index: number;
  field: FieldArrayWithId<ConsumptionValidationSchema, 'line_items_attributes', 'id'>;
  updateTotalValues: () => void;
  onDelete: () => void;
}

const ProductItem = ({ index, field, updateTotalValues, onDelete }: ProductItemProps) => {
  const {
    control,
  } = useFormContext<ConsumptionValidationSchema>();

  return (
    <Box className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <p className="text-[18px]">{field?.display_name}</p>

        <Trash strokeWidth={1} className="cursor-pointer" color="var(--warning)" onClick={() => onDelete()} />
      </div>

      <div className="flex items-center gap-6">
        <div className="grow">
          <Controller
            name={`line_items_attributes.${index}.quantity`}
            control={control}
            render={({ field: inputField }) => (
              <UnitTextField
                unit={
                  field.unit_for_quantity
                    ? unityForQuantityOptions.find((option) => option.value === field.unit_for_quantity)?.symbol || ''
                    : ''
                }
                label="Quantidade"
                {...inputField}
                onChange={(event) => {
                  inputField.onChange(event);
                  updateTotalValues();
                }}
              />
            )}
          />
        </div>

        <div className="grow">
          <Controller
            name={`line_items_attributes.${index}.unitary_amount`}
            control={control}
            render={({ field }) => (
              <CurrencyTextField
                label="Valor unit."
                {...field}
                onChange={(event) => {
                  field.onChange(event);
                  updateTotalValues();
                }}
              />
            )}
          />
        </div>
      </div>
    </Box>
  );
};

export default ProductItem;
