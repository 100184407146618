import { BadgeHelp } from "lucide-react"
import { Link } from "react-router-dom"
import Button from "../Button"

export type ListHeaderProps = {
  Icon: JSX.Element
  title: string
  createButtonText?: string
}

const ListHeader = ({
  Icon,
  title,
  createButtonText
}: ListHeaderProps) => {
  return (
    <div className="w-full flex justify-between items-center">
      <div className="flex gap-2 items-center">
        {Icon}
        <h2 className="text-2xl font-bold">{title}</h2>
        <BadgeHelp strokeWidth={1} color="var(--warning)" />
      </div>
      {createButtonText 
        ? <Link to='create'>
            <Button className="shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)]">{createButtonText}</Button>
          </Link>
        : null  
      }
    </div>
  )
}

export default ListHeader