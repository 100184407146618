export const unFormatCurrency = (value: string) => {
  return Number(value.substring(3).replace(/[\.\-() ]/g, '').replace(/[\,\-() ]/g, '.'))
}

export const unFormatPercentage = (value: string) => {
  return Number(value.substring(0, value.length - 2).replace(/[\,\-() ]/g, '.'))/100
}

export const removeSpecialCharacters = (value: string) => {
  return value.replace(/[^a-zA-Z0-9]/g, '')
}

export const unFormatCnpj = (value: string) => {
  return value.replace(/\D/g, '')
}

export const unFormatUnit = (value: string, unit: string): number => {
  return Number(value.replace(` ${unit}`, ''))
}

export const unFormatUnknownUnit = (value: string) : number => {
  return Number(value.split(' ')[0].replace('.', '').replace(',', '.'))
}
