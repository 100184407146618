import AddressForm from '@/common/components/AddressForm';
import Box from '@/common/components/Box';
import { ShowHeader } from '@/common/components/ShowHeader';
import { AddressData } from '@/common/types/address';
import { AccreditationInformationSection } from '@/features/company/components/Profile/AccreditationInformationSection';
import { GeneralInformationSection } from '@/features/company/components/Profile/GeneralInformationSection';
import { useMineCompany } from '@/features/company/hooks/useMineCompany';

const CompanyProfile = () => {
  const { data: company, isPending } = useMineCompany();

  return (
    <div>
      <ShowHeader
        logoUrl={company?.general_information.company_logo?.url}
        displayName={company?.general_information.fantasy_name}
        backTo="/company"
      />

      <Box className="flex flex-col gap-6 h-[calc(100vh-130px)] overflow-y-scroll">
        <AccreditationInformationSection
          matrixBranch={company?.matrix_branch}
          productCategories={company?.product_categories}
        />

        <GeneralInformationSection
          generalInformation={company?.general_information}
        />

        <div className="flex flex-col gap-4">
          <p className="text-[18px] font-bold">
            Endereço
          </p>

          {!isPending && (
            <AddressForm
              initialAddress={(company?.general_information?.address || {}) as AddressData}
              errors={{}}
              disableFields
            />
          )}
        </div>
      </Box>
    </div>
  );
};

export default CompanyProfile;
