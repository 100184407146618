import { ImageUploader } from "@/common/components/ImageUploader";
import Select from "@/common/components/Select";
import TextField from "@/common/components/TextField";
import { CompanyData } from "../../types/company.type";
import { formatCnpj } from "@/common/utils/formatters";

interface GeneralInformationSectionProps {
  generalInformation?: CompanyData['general_information'];
}

export const GeneralInformationSection = ({
  generalInformation,
}: GeneralInformationSectionProps) => {
  return (
    <div className="flex flex-col gap-4">
      <p className="text-[18px] font-bold">
        Dados Gerais
      </p>

      <div className="flex gap-3 items-center">
        <ImageUploader
          isLoading={false}
          label="Foto"
          name="profile-picture"
          currentImageUrl={generalInformation?.company_logo?.url}
          disabled
        />

        <TextField
          className="w-full"
          label="CNPJ"
          defaultValue={formatCnpj(generalInformation?.cnpj || '')}
          disabled
        />
      </div>

      <div className="flex gap-2">
        <TextField
          label="Nome Fantasia"
          className="w-1/2"
          maxLength={64}
          defaultValue={generalInformation?.fantasy_name}
          disabled
        />

        <TextField
          label="Nome Empresarial"
          className="w-1/2"
          maxLength={64}
          defaultValue={generalInformation?.official_name}
          disabled
        />
      </div>

      <TextField
        label="Endereço eletrônico"
        maxLength={64}
        defaultValue={generalInformation?.email}
        disabled
      />

      <div className="flex gap-2">
        <TextField
          label="IE (Inscrição Estadual)"
          defaultValue={generalInformation?.state_inscription}
          className="w-1/2"
          disabled
        />

        <TextField
          label="CNAE"
          maxLength={7}
          defaultValue={generalInformation?.primary_cnae}
          className="w-1/2"
          disabled
        />
      </div>

      <Select
        label="Bandeira"
        placeholder
        value={generalInformation?.flag && ({ value: generalInformation?.flag.id, label: generalInformation?.flag.display_name })}
        isDisabled
      />

      <TextField
        label="Site ou Redes sociais"
        defaultValue={generalInformation?.website}
        disabled
      />
    </div>
  );
};
