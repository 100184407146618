import usePrivateAxios from "@/common/api/usePrivateAxios";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { ContractData, ContractResponse } from "../types/contract.type";

export const useMineContract: () => UseQueryResult<ContractData, unknown> = () => {
  const privateAxios = usePrivateAxios();

  return useQuery<ContractResponse, unknown, ContractData>({
    queryKey: ['contractsMine'],
    queryFn: () => privateAxios.get(`/contracts/mine`),
    select: (data) => data?.data?.data, 
  });
};
