import usePrivateAxios from "@/common/api/usePrivateAxios";
import { defaultTransformerResponse } from "@/common/constants/defaultTransformerResponse.constant"
import { Mapper } from "@/common/services/mapper"
import { MetaResponse } from "@/common/types/metaResponse"
import { keepPreviousData, useQuery } from "@tanstack/react-query"

type UseProductsProps<T> = {
  filters?: {
    search?: string
    page?: number
    category?: string
  }
  mapper?: (data: any) => T,
}

export const useProducts = <T,>({
  filters: { search = '', page, category } = {},
  mapper = Mapper.default<T>(),
}: UseProductsProps<T>) => {
  const privateAxios = usePrivateAxios();

  const {
    data: { data, meta } = defaultTransformerResponse<T>(),
    ...rest
  } = useQuery({
    queryKey: ['products', search, page, category],
    queryFn: () => {
      return privateAxios.get(`/products`, {
        params: {
          search,
          page,
          'q[category_id_eq]': category
        }
      })
    },
    select: (data: { data: { data: any[], meta: MetaResponse } }) => {
      return {
        data: mapper(data?.data?.data),
        meta: data?.data?.meta,
      };
    },
    placeholderData: keepPreviousData,
  })

  return { data, meta, ...rest }
}
