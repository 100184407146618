import { getCompanyDomain } from "@/common/services/companyDomainGetSet";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const PublicCompanyRoute = () => {
  const location = useLocation();
  const companyDomain = getCompanyDomain();

  return companyDomain ? <Outlet /> : <Navigate to='/' state={{ from: location }} />;
};

export default PublicCompanyRoute;