import { useLocation, useNavigate } from 'react-router-dom'

export const useGoBack = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const thereIsAPrevPage = location.key !== 'default'

  if (thereIsAPrevPage) {
    return (_: { fallback?: string }) => navigate(-1)
  }

  return ({ fallback }: { fallback?: string }) => navigate(fallback || '/')
}
