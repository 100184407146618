import Accordion from "@/common/components/Accordion";
import Select from "@/common/components/Select";
import { ContractData } from "../../types/contract.type";
import { Mapper } from "@/common/services/mapper";

interface ContractProductsSectionProps {
  contract?: ContractData;
}

const ProductsSection = ({ contract }: ContractProductsSectionProps) => {
  return (
    <Accordion
      head={
        <p>Produtos proibidos</p>
      }
      body={
        <Select
          placeholder="Produtos"
          value={Mapper.mapToOptions({ valueFieldName: "id", labelFieldName: "display_name" })(contract?.products || [])}
          isMulti
          isDisabled
        />
      }
    />
  );
};

export default ProductsSection;
