import { StylesConfig, ClassNamesConfig } from "react-select";
import { twMerge } from "tailwind-merge";

export const styles: StylesConfig<unknown> = {
  option: (styles, { isSelected, isFocused,  }) => ({
    ...styles,
    backgroundColor:
      isSelected
      ? '#0C8CE9'
      : isFocused
      ? '#f1f1f1'
      : undefined,
    color: isFocused && !isSelected ? 'black' : 'white'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  control: (styles, { hasValue }) => ({
    ...styles,
    backgroundColor: hasValue ? '#49565C' : ''
  })
}

export const controlStyles: ClassNamesConfig<unknown> = {
  control: () => 'p-2 w-full rounded-md !min-h-[52px] !bg-neutral-op-8 outline-none !border-none',
  indicatorsContainer: () => 'mb-auto',
  container: () => 'w-full !border-none !outline-none',
  menu: () => '!bg-[#383838]',
  singleValue: () => '!text-white-800 text-sm',
  input: () => '!text-white-800 text-sm',
  multiValueLabel: () => 'bg-black-600 !text-white-800 !rounded-none px-1.5',
  multiValueRemove: ({ isDisabled }) => twMerge('bg-black rounded-0 !rounded-none', isDisabled && 'hidden'),
  dropdownIndicator: ({ isDisabled }) => twMerge('text-white-800 hover:text-white-300 cursor-pointer', isDisabled && 'hidden'),
  clearIndicator: () => 'text-white-800 hover:text-white-300 cursor-pointer',
  option: ({ isSelected }) => isSelected ? 'bg-blue-700 text-white-800' : 'bg-[#383838] text-white-800 hover:bg-[#444444]',
}

export const disabledControlStyles: ClassNamesConfig<unknown> = {
  ...controlStyles,
  singleValue: () => '!text-white-300 text-sm',
}

export const badgeControlStyles: ClassNamesConfig<unknown> = {
  control: () => 'w-full !flex !min-h-[32px] !h-[32px] !justify-center !rounded-xl outline-none !border-none px-2',
  container: () => 'w-full !border-none !outline-none',
  indicatorsContainer: () => '!h-8',
  menu: () => '!bg-[#383838]',
  valueContainer: () => '!py-0',
  singleValue: () => '!text-white-800',
  placeholder: () => '!text-white-800 italic',
  input: () => 'text-white-400',
  option: ({ isSelected }) => isSelected ? 'bg-blue-700 text-white-800' : 'bg-[#383838] text-white-800 hover:bg-[#444444]',
}
