import Modal from '@/common/components/Modal';
import { BillingTransactionIdentifier } from '@/common/types/billing/transactionIdentifier.type';
import { Dispatch, SetStateAction } from 'react';

interface ModalSuccessProps {
  data: BillingTransactionIdentifier;
  setData: Dispatch<SetStateAction<boolean | BillingTransactionIdentifier>>;
  clearForm: () => void;
}

const ModalSuccess = ({ data, setData, clearForm }: ModalSuccessProps) => {
  const closeModalAndClearForm = () => {
    setData(false);
    clearForm();
  };

  return (
    <Modal
      isOpen={!!data}
      setIsOpen={closeModalAndClearForm}
      onConfirm={closeModalAndClearForm}
      Trigger={<></>}
      footer="Entendido!"
      title="Estorno bem sucedido!"
      body={
        <div className="flex flex-col gap-2 py-2">
          <div>
            <p className="text-[14px] font-light">Número do estorno</p>
            <p className="text-[16px] text-2xl text-muted">{data?.id}</p>
          </div>
        </div>
      }
    />
  );
};

export default ModalSuccess;
