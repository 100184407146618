import Accordion from "@/common/components/Accordion";
import Select from "@/common/components/Select";
import { ContractData } from "../../types/contract.type";
import { keyToObject as billingCycleKeyToObject } from "@/common/constants/billingCycleOptions.contant";
import TextField from "@/common/components/TextField";
import { Mapper } from "@/common/services/mapper";

interface InvoiceDetailsSectionProps {
  contract?: ContractData;
}

const InvoiceDetailsSection = ({ contract }: InvoiceDetailsSectionProps) => {
  return (
    <Accordion
      head={
        <p>Detalhes da fatura</p>
      }
      body={
        <div className="flex flex-col gap-4">
          <Select
            label="Ciclo de fatura"
            value={contract?.cycle && billingCycleKeyToObject(contract?.cycle)}
            isDisabled
          />

          <div className="flex gap-2">
            <TextField
              label="Dia de abertura"
              className="w-1/2"
              value={`${contract?.cycle_start_day}`}
              disabled
            />

            <TextField
              label="Vencimento da fatura"
              className="w-1/2"
              value={contract?.closing_to_due_in_days ? `D + ${contract?.closing_to_due_in_days}` : ''}
              disabled
            />
          </div>

          <Select
            label="Serviços"
            value={Mapper.mapToOptions({ valueFieldName: "id", labelFieldName: "display_name" })(contract?.product_categories || [])}
            isMulti
            isDisabled
          />
        </div>
      }
    />
  );
};

export default InvoiceDetailsSection;
