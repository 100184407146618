import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/common/components/ui/accordion"
import { createRef, useEffect } from "react"
import { twMerge } from "tailwind-merge"

export interface AccordionProps {
  head: JSX.Element | string
  body: JSX.Element | string
  value: string
  opened?: boolean
  selected?: boolean
}

const Accordion = ({
  head,
  body,
  value,
  opened,
  selected = false,
}: AccordionProps) => {
  const contentRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (opened) {
      contentRef.current?.classList.remove("hidden")
    } else {
      setTimeout(() => contentRef.current?.classList.add("hidden"), 100)
    }
  }, [opened])

  return (
    <AccordionItem value={value}>
      <AccordionTrigger 
        className={twMerge("pr-4 data-[state=open]:bg-black-900 [&[data-state=open]>svg]:text-blue-800", selected ? '!bg-blue-200' : '')}
      >{head}</AccordionTrigger>
      <AccordionContent ref={contentRef} className="bg-black-900">
        {body}
      </AccordionContent>
    </AccordionItem>
  )
}

export default Accordion