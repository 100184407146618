export default (data: any) => {
  return [
    {
      label: "Faturamento",
      value: data.consumed_total,
    },
    {
      label: "Descontos",
      value: data.discount_total,
    },
    {
      label: "Total em tarifas",
      value: data.fees_total,
    },
    {
      label: "Pagáveis",
      value: data.payable,
      textClassName: "text-yellow-400",
    },
  ];
};
