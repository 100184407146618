import Box from '@/common/components/Box'
import ListHeader from '@/common/components/ListHeader'
import { zodResolver } from '@hookform/resolvers/zod'
import { Receipt } from 'lucide-react'
import { useState } from 'react'
import { FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import ModalError from '../../components/ModalError'
import ModalSuccess from '../../components/ModalSuccess'
import TabInformation from '../../components/TabInformation'
import TabResume from '../../components/TabResume'
import useCreateConsumption from '../../hooks/useCreateConsumption'
import { ConsumptionValidationSchema, createSchema } from '../../schemas/create.schema'
import { ConsumptionData, ConsumptionErrorResponseData } from '../../types/consumption.type'
import ModalPassword from '@/common/components/ModalPassword'

const Create = () => {
  const [activeTab, setActiveTab] = useState(0)
  const [modalPasswordIsOpen, setModalPasswordIsOpen] = useState(false)

  const [modalSuccessData, setModalSuccessData] = useState<ConsumptionData | boolean>(false)
  const [modalErrorData, setModalErrorData] = useState<ConsumptionErrorResponseData | boolean>(false)

  const useFormMethods = useForm<ConsumptionValidationSchema>({
    resolver: zodResolver(createSchema),
  })

  const { fields: lineItemsFields, remove: removeLineItem } = useFieldArray({
    control: useFormMethods.control,
    name: 'line_items_attributes',
  })

  const { mutate: createConsumption, isPending } = useCreateConsumption({
    onSuccess: (response) => {
      setModalSuccessData(response.data.data)
    },
    onError: (response) => {
      useFormMethods.setValue('consumption_password', '')
      setModalErrorData(response)
    },
  })

  const onSubmit: SubmitHandler<ConsumptionValidationSchema> = async (data) => {
    const isValid = await useFormMethods.trigger()

    if (!isValid) return

    return createConsumption(data)
  }

  const clearForm = () => {
    useFormMethods.reset()
    removeLineItem([...Array(lineItemsFields.length).keys()])
    setActiveTab(0)
  }

  const handleTabChange = async (tabIndex: number) => {
    if (tabIndex > activeTab) {
      const isValid = await useFormMethods.trigger(['card_identifier', 'card_id', 'buyer_cpf', 'line_items_attributes'])

      if (!isValid) return
    }

    setActiveTab(tabIndex)
  }

  return (
    <FormProvider {...useFormMethods}>
      <form className="flex flex-col gap-6 h-full" onSubmit={useFormMethods.handleSubmit(onSubmit)}>
        <ListHeader Icon={<Receipt className="w-10 h-10" strokeWidth={1.5} />} title="Consumo" />

        <Box className="flex flex-col gap-6 grow h-[calc((100vh-276px)/2)]">
          {activeTab === 0 && (
            <TabInformation
              key="tabInformation"
              activeTab={activeTab}
              handleTabChange={handleTabChange}
              clearForm={clearForm}
            />
          )}

          {activeTab === 1 && (
            <TabResume
              key="tabResume"
              activeTab={activeTab}
              handleTabChange={handleTabChange}
              openPasswordModal={setModalPasswordIsOpen}
              isPending={isPending}
            />
          )}
        </Box>
      </form>

      <ModalPassword
        fieldName="consumption_password"
        isOpen={modalPasswordIsOpen}
        setIsOpen={setModalPasswordIsOpen}
        submit={() => useFormMethods.handleSubmit(onSubmit)()}
      />

      <ModalSuccess
        data={modalSuccessData as ConsumptionData}
        setData={setModalSuccessData as React.Dispatch<React.SetStateAction<boolean>>}
        clearForm={clearForm}
      />

      <ModalError
        data={modalErrorData as ConsumptionErrorResponseData}
        setData={setModalErrorData as React.Dispatch<React.SetStateAction<boolean>>}
      />
    </FormProvider>
  )
}

export default Create
