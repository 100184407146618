import usePrivateAxios from "@/common/api/usePrivateAxios";
import { defaultTransformerResponse } from "@/common/constants/defaultTransformerResponse.constant"
import { Mapper } from "@/common/services/mapper"
import { MetaResponse } from "@/common/types/metaResponse"
import { keepPreviousData, useQuery } from "@tanstack/react-query"

type UseRolesProps<T> = {
  filters?: {
    search?: string
    page?: number
  }
  mapper?: (data: any) => T,
}

export const useRoles = <T,>({
  filters: { search = '', page } = {},
  mapper = Mapper.default<T>(),
}: UseRolesProps<T>) => {
  const privateAxios = usePrivateAxios();

  const {
    data: { data, meta } = defaultTransformerResponse<T>(),
    ...rest
  } = useQuery({
    queryKey: ['roles', search, page],
    queryFn: () => {
      return privateAxios.get(`/roles`, {
        params: {
          search,
          page,
        }
      })
    },
    select: (data: { data: { data: any[], meta: MetaResponse } }) => {
      return {
        data: mapper(data?.data?.data),
        meta: data?.data?.meta,
      }
    },
    placeholderData: keepPreviousData,
  })

  return { data, meta, ...rest }
}
