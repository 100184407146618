import usePrivateAxios from "@/common/api/usePrivateAxios";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { RefundValidationSchema } from "../schemas/refund.schema";

interface useCreateRefundProps {
  onSuccess: (response: AxiosResponse) => void;
  onError: (error: any) => void;
}

const useCreateRefund = ({ onSuccess, onError }: useCreateRefundProps) => {
  const privateAxios = usePrivateAxios();

  return useMutation({
    mutationKey: ['createRefund'],
    mutationFn: async (form: RefundValidationSchema) => {
      const payload = {
        ...form,
      }

      return privateAxios.post('/billing/refunds', {
        refund: payload,
      });
    },
    onSuccess,
    onError,
  });
}

export default useCreateRefund;
