import { UserRoundIcon } from "lucide-react";
import { ProductPriceData } from "../types/productPrice.type";
import { unitiesLabel } from "@/common/constants/unitStrategies.contants";
import { ImageWithPlaceholder } from "@/common/components/ImageWithPlaceholder";
import { dateTimeTransformer } from "@/common/utils/dateTransformer";
import EditProductPriceModal from "../components/EditProductPriceModal";

export class ProductPricesTransformer {
  static tableTransformer() {
    return (productPrices: ProductPriceData[]) => {
      return productPrices.map((productPrice) => ({
        ...productPrice,
        product_category_display_name: productPrice?.product?.category?.display_name || '',
        product_display_name: productPrice?.product?.display_name || '',
        unit_for_quantity: productPrice?.product?.unit_for_quantity ? unitiesLabel[productPrice.product.unit_for_quantity] : '',
        value: (
          <EditProductPriceModal
            productPrice={productPrice}
          />
        ),
        last_update_by: productPrice.last_updated_by && (
          <div className="flex items-center gap-2">
            <ImageWithPlaceholder
              url={productPrice.last_updated_by?.profile_picture?.url}
              alt={productPrice.last_updated_by?.full_name}
              className="size-6"
              iconClassName="size-4"
              placeholderIcon={UserRoundIcon}
            />

            <div className="flex flex-col gap-1">
              <p className="underline">{productPrice.last_updated_by?.full_name}</p>
              <p>{dateTimeTransformer(new Date(productPrice.updated_at))}</p>
            </div>
          </div>
        )
      }));
    };
  }

  static errorsTransformer() {
    return (errors: Record<string, string[]>) => {
      return Object.entries(errors).map(([key, value]) => {
        if (key === 'product_id') {
          key = 'product';
        }

        return {
          [key]: value.join(', ')
        };
      });
    };
  }
}
