import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

type Position = {
  top?: number;
  bottom?: number;
  right?: number;
  left?: number;
};

interface TooltipProps {
  children: React.ReactNode;
  target: HTMLElement | null;
  expectedWidth?: number;
  expectedHeight?: number;
}

const TooltipPattern = ({ children, target, expectedWidth, expectedHeight }: TooltipProps) => {
  const [show, setShow] = useState(false);
  const [position, setPosition] = useState<Position>({});

  useEffect(() => {
    if (target) {
      const rect = target.getBoundingClientRect();

      const newPosition: Position = {};

      if (expectedHeight && rect.bottom + expectedHeight > window.innerHeight) {
        newPosition.bottom = window.innerHeight - rect.top + window.scrollY;
      } else {
        newPosition.top = rect.top + window.scrollY + rect.height;
      }
      
      if (expectedWidth && rect.right + expectedWidth > window.innerWidth) {
        newPosition.right = window.innerWidth - rect.right + window.scrollX;
      } else {
        newPosition.left = rect.left + window.scrollX;
      }

      setPosition(newPosition);

      setShow(true);
    }
  }, []);

  return createPortal(
    <div
      className={`tooltip-class ${!show ? 'hidden' : ''}`}
      style={{
        position: "absolute",
        zIndex: 5000,
        ...position,
      }}
    >
      {children}
    </div>,
    document.body
  );
};

export default TooltipPattern;
