import Box from '@/common/components/Box';
import Button from '@/common/components/Button';
import Select from '@/common/components/Select';
import TextField from '@/common/components/TextField';
import { userStatusTranslation } from '@/common/constants/userStatus.constants';
import useUser from '@/common/hooks/useUser';
import { dateTransformer } from '@/common/utils/dateTransformer';
import { formatCpf, formatPhoneNumber } from '@/common/utils/formatters';
import RequestPasswordResponseModal from '@/features/users/components/RequestPasswordResponseModal';
import TemporaryPasswordModal from '@/features/users/components/TemporaryPasswordModal';
import { useEstablishmentUser } from '@/features/users/hooks/useEstablishmentUser';
import { useRequestPasswordReset } from '@/features/users/hooks/useRequestPasswordReset';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

const GeneralInformationTab = () => {
  const { id: userId } = useParams();

  const { data: userMe } = useUser({ id: 'me' });

  const { data: establishmentUserData } = useEstablishmentUser({ id: userId || '' });

  const userPermissionsSet = new Set(userMe?.permissions?.map(({ operation_klass }) => operation_klass));

  const [temporaryPassword, setTemporaryPassword] = useState('');
  const [requestPasswordResponse, setRequestPasswordResponse] = useState<string[] | null>(null);

  const { mutate: requestPasswordReset, isPending: requestPasswordResetIsPending } = useRequestPasswordReset({
    userId,
    onSuccess: (response) => {
      const data = response.data.data;

      if (data.temporary_password && data.visible_password) {
        setTemporaryPassword(response.data.data.visible_password);
      }
    },
    onError: (error) => {
      setRequestPasswordResponse((error.response?.data as { errors: { base: string[]; }; })?.errors?.base || null);
    },
  });

  return (
    <>
      <Box className="flex flex-col gap-4 shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)] overflow-y-auto max-h-[calc(100vh-128px)]">
        <p className="text-lg font-bold">Dados pessoais</p>
        <div className="flex gap-2 w-full justify-between">
          <TextField
            label="CPF"
            className="w-3/4"
            value={
              establishmentUserData?.cpf
                ? formatCpf(establishmentUserData?.cpf)
                : ''
            }
            disabled
          />
          <TextField
            label="Data de nascimento"
            className="w-1/4"
            value={
              establishmentUserData?.date_of_birth
                ? dateTransformer(establishmentUserData?.date_of_birth)
                : ''
            }
            disabled
          />
        </div>
        <div className="flex gap-2 w-full justify-between">
          <TextField
            label="Nome"
            className="w-3/4"
            value={establishmentUserData?.full_name}
            disabled
          />
          <Select
            label="Perfil"
            className="w-1/4"
            value={establishmentUserData?.user_roles?.map(({ role }) => ({
              label: role.display_name,
              value: role.id,
            }))}
            isMulti
            isDisabled
          />
        </div>
        <TextField
          label="Status"
          value={
            userStatusTranslation[
            (establishmentUserData?.status ||
              'default') as keyof typeof userStatusTranslation
            ]
          }
          disabled
        />
        <TextField
          label="Matrícula"
          value={establishmentUserData?.enrollment_number}
          disabled
        />
        <TextField
          label="Telefone"
          value={establishmentUserData?.phone?.number ? formatPhoneNumber(establishmentUserData?.phone?.number) : ''}
          disabled
        />
        <TextField
          label="Email"
          value={establishmentUserData?.email}
          disabled
        />

        {establishmentUserData && userMe?.id !== userId && userPermissionsSet.has('Client::Users::RequestPasswordReset') && (
          <div className="w-full rounded-sm p-4 bg-white-100">
            <div className="flex w-full items-center justify-between">
              <p>Senha</p>

              <Button className="shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)]" disabled={requestPasswordResetIsPending} onClick={() => requestPasswordReset()}>
                Redefinir senha
              </Button>
            </div>
          </div>
        )}
      </Box>

      <RequestPasswordResponseModal
        requestPasswordResponse={requestPasswordResponse}
        setRequestPasswordResponse={setRequestPasswordResponse}
      />

      <TemporaryPasswordModal
        temporaryPassword={temporaryPassword}
        setTemporaryPassword={setTemporaryPassword}
      />
    </>
  );
};

export default GeneralInformationTab;
