import IconButton from "@/common/components/IconButton"
import { useMenu } from "@/common/stores/menu"
import { twMerge } from "tailwind-merge"

export interface ButtonProps {
  label: string
  Icon: JSX.Element
  active?: boolean
  showDot?: boolean
  accordion?: boolean
}

const Button = ({
  label,
  Icon,
  active = false,
  showDot = false,
  accordion = false
}: ButtonProps) => {
  const { collapse } = useMenu()

  if(collapse){
    return (
      <IconButton 
        Icon={Icon}
        className={active ? 'bg-neutral-op-8' : ''}
      />
    )
  }

  return (
    <div 
      className={twMerge("w-[283px] items-center flex justify-start gap-2 py-4 px-6 font-light text-xs", active ? 'bg-blue-200' : 'cursor-pointer', accordion && 'w-[243px]')}
    >
      {active || showDot
        ? (
          <div className="w-1 h-6 bg-blue-800 rounded-full" ></div>
        )
        : null
      }
      {Icon}
      <span>{label}</span>
    </div>
  )
}

export default Button