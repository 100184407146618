import { Dialog, DialogContent } from '@/common/components/ui/dialog';
import { InputOTP, InputOTPGroup, InputOTPSlot } from '@/common/components/ui/inputOTP';
import { Dispatch, SetStateAction } from 'react';
import { useFormContext } from 'react-hook-form';

interface ModalPasswordProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  submit: () => void;
  fieldName: string;
}

const ModalPassword = ({ isOpen, setIsOpen, submit, fieldName }: ModalPasswordProps) => {
  const { watch, setValue } = useFormContext();

  const consumptionPassword = watch(fieldName);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="bg-white-100 p-6 rounded-none backdrop-blur-[160px]">
        <div className="flex flex-col items-center gap-6">
          <p className="font-bold text-2xl">Informe sua senha de consumo</p>

          <InputOTP
            maxLength={6}
            value={consumptionPassword}
            onChange={(e) => {
              setValue('consumption_password', e);
            }}
            onComplete={() => {
              setIsOpen(false);
              submit();
            }}
          >
            <InputOTPGroup className="gap-2">
              {[...Array(6)].map((_, index) => (
                <InputOTPSlot
                  key={index}
                  index={index}
                  hidePassword
                  className="bg-white-100 border-none rounded-none size-[60px]"
                />
              ))}
            </InputOTPGroup>
          </InputOTP>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ModalPassword;
