import Box from '@/common/components/Box'
import Select from '@/common/components/Select'
import { useProducts } from '@/common/hooks/queries/useProducts'
import { Mapper } from '@/common/services/mapper'
import { ProductData } from '@/common/types/product.type'
import { formatCurrency } from '@/common/utils/formatters'
import { unFormatCurrency, unFormatUnknownUnit } from '@/common/utils/unFormatters'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { ConsumptionValidationSchema } from '../schemas/create.schema'
import ProductItem from './ProductItem'
import { numberToFixedHalfDown } from '@/common/utils/numberToFixedHalfDown';

const ProductsForm = () => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<ConsumptionValidationSchema>()

  const {
    fields: lineItemsFields,
    append: appendLineItem,
    remove: removeLineItem,
  } = useFieldArray({
    control,
    name: 'line_items_attributes',
  })

  const watchProducts = watch('line_items_attributes')

  const { data: products } = useProducts<ProductData[]>({})

  const handleRemoveProduct = (index: number) => {
    removeLineItem(index)
    updateTotalValues({ index })
  }

  const handleAppendProduct = (option: { value: string; label: string }) => {
    if ((option as { value: string }).value) {
      const product = products?.find((product) => product.id === option.value)

      appendLineItem({
        product_id: option?.value,
        quantity: '',
        unitary_amount: '',
        amount: '0',
        display_name: option?.label,
        unit_for_quantity: product?.unit_for_quantity,
      })
    }
  }

  const updateTotalValues = ({ index = -1 }: { index?: number } = {}) => {
    if (watchProducts.length) {
      let updatedWatchProducts = [...watchProducts]

      if (index >= 0) {
        updatedWatchProducts = updatedWatchProducts.filter((_, i) => i !== index)
      }

      const totalQuantity = updatedWatchProducts.reduce((acc: number, product: any) => {
        return acc + (unFormatUnknownUnit(product?.quantity) || 0)
      }, 0)

      const totalAmount = updatedWatchProducts.reduce((acc: number, product: any) => {
        return (
          acc +
          Number(numberToFixedHalfDown((unFormatUnknownUnit(product?.quantity) || 0) * (Number(unFormatCurrency(product?.unitary_amount)) || 0), 2))
        )
      }, 0)

      setValue('total_quantity', totalQuantity)
      setValue('total_amount', totalAmount)

      updatedWatchProducts.forEach((product: any, index: number) => {
        const totalProductAmount =
          (unFormatUnknownUnit(product?.quantity) || 0) * (Number(unFormatCurrency(product.unitary_amount)) || 0)

        setValue(`line_items_attributes.${index}.amount`, formatCurrency(Number(numberToFixedHalfDown(totalProductAmount, 2))))
      })
    }
  }

  return (
    <div className="flex flex-col gap-6 h-full">
      <p className="text-[20px] font-bold">Produtos transacionados</p>

      <Box className="flex flex-col gap-6 h-full">
        <div className="flex flex-col gap-2">
          <Select
            placeholder="Pesquisar produtos"
            options={Mapper.mapToOptions<ProductData>({ labelFieldName: 'display_name', valueFieldName: 'id' })(
              products,
            )}
            value=""
            onChange={(option) => handleAppendProduct(option as { value: string; label: string })}
          />

          {errors?.line_items_attributes && (
            <p className="text-[14px] text-danger-soft">{errors?.line_items_attributes?.message}</p>
          )}
        </div>

        <div className="flex flex-col gap-4 overflow-y-auto">
          {lineItemsFields.map((field, index) => (
            <ProductItem
              key={field.id + index}
              index={index}
              field={field}
              updateTotalValues={updateTotalValues}
              onDelete={() => handleRemoveProduct(index)}
            />
          ))}
        </div>
      </Box>
    </div>
  )
}

export default ProductsForm
