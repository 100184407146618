import { Trash2Icon } from "lucide-react"
import { useState } from "react"
import { twMerge } from "tailwind-merge"

interface ImageCarouselProps {
  files: { id: string, file: File | string }[]
  showRemoveButton?: boolean
  handleRemoveImage?: (index: number) => void
}

export const ImageCarousel = ({ files, showRemoveButton, handleRemoveImage }: ImageCarouselProps) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  return (
    <div className="group relative size-72 flex justify-center items-center rounded-lg border overflow-hidden flex-shrink-0">
      <img
        src={typeof files[currentIndex].file === 'string' ? files[currentIndex].file : URL.createObjectURL(files[currentIndex].file)}
        className="object-cover w-full h-full rounded"
        alt={`uploaded-${currentIndex}`}
      />
      {showRemoveButton && (
        <button
          type="button"
          className="absolute top-2 right-2 bg-black-300 text-yellow-600 p-1 rounded-full text-white transition text hover:bg-black-600 opacity-0 group-hover:opacity-100"
          onClick={() => {
            handleRemoveImage?.(currentIndex)
            setCurrentIndex(currentIndex - 1 < 0 ? 0 : currentIndex - 1)
          }}
        >
          <Trash2Icon strokeWidth={1} />
        </button>
      )}
      <div className="absolute bottom-4 flex flex-wrap justify-center gap-2 px-4">
        {files.map((_, index) => (
          <button
            key={index}
            type="button"
            className={twMerge('size-2 rounded-full bg-white-300 border-[0.5px] border-[#444444]', index === currentIndex && 'bg-blue-800')}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
  )
}
