import { isValidDate } from "@/common/utils/dateValidator";
import { formatDate } from "@/common/utils/formatters";

export const dateTransformer = (date: string) => {
  const [year, month, day] = date.split('-');
  return `${day}/${month}/${year}`;
};

export const dateTimeTransformer = (date: Date) => {
  if (!isValidDate(date)) return '';

  const formattedDate = formatDate(date);
  const formattedMinutes = String(date.getMinutes()).padStart(2, '0');

  return `${formattedDate} - ${date.getHours()}:${formattedMinutes}`;
};

export const dateToISOStingTransformer = (date: Date | string | undefined) => {
  if (!date) {
    return "";
  }
  
  if (date instanceof Date) {
    date = date.toISOString();
  }

  return date ? `${date?.split("T")[0]}` : "";
}
