import usePrivateAxios from "@/common/api/usePrivateAxios";
import { CardCurrentLimitsData, CardCurrentLimitsResponse } from "@/features/cardCurrentLimits/types/cardCurrentLimits.type";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

export const useCardCurrentLimits: (id?: string, product_id?: string) => UseQueryResult<CardCurrentLimitsData, unknown> = (id?: string, product_id?: string) => {
  const privateAxios = usePrivateAxios();

  return useQuery<CardCurrentLimitsResponse, unknown, CardCurrentLimitsData>({
    enabled: !!(id && product_id),
    queryKey: ['cardCurrentLimits', id, product_id],
    queryFn: () => privateAxios.get(`/client/cards/${id}/current_limits`, { params: { product_id } }),
    select: (data) => data?.data?.data, 
  });
};
