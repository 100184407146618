import Table from '@/common/components/Table';
import { BillingTransactionIdentifier } from '@/common/types/billing/transactionIdentifier.type';
import { CardDetailsData } from '@/common/types/cards/details.type';
import EquipmentInfo from '@/features/consumption/components/EquipmentInfo';
import refundTableColumnsContant from '../constants/refundTableColumns.contant';
import { BillingTransactionIdentifiersTransformer } from '../services/billingTransactionIdentifiersTransformer';

interface TransactionInformationProps {
  transactionIdentifiers: BillingTransactionIdentifier;
  transactionIdentifiersIsPending: boolean;
}

export const TransactionInformation = ({ transactionIdentifiers, transactionIdentifiersIsPending }: TransactionInformationProps) => {
  return (
    <>
      <div className="w-full space-y-4">
        {transactionIdentifiers?.eventable?.card && (
          <EquipmentInfo cardDetails={transactionIdentifiers?.eventable?.card as unknown as CardDetailsData} />
        )}
      </div>
      <Table
        columns={refundTableColumnsContant}
        isPendingData={transactionIdentifiersIsPending}
        rows={BillingTransactionIdentifiersTransformer.tableTransformer()(
          transactionIdentifiers.eventable?.line_items || [],
        )}
        page={1}
        totalPages={1}
      />
    </>
  );
};
