import { dateTransformer } from '@/common/utils/dateTransformer'
import { formatCpf } from '@/common/utils/formatters'
import { transformRolesToOptions } from '@/common/utils/roleTransformers'
import { statusOptions } from '@/features/users/constants/statusOptions.constant'
import { EstablishmentUserData } from '@/features/users/types/establishmentUsers'

export const establishmentUserDefaultValues = (
  establishmentUser?: EstablishmentUserData,
) => ({
  cpf: establishmentUser?.cpf ? formatCpf(establishmentUser?.cpf) : undefined,
  date_of_birth: establishmentUser?.date_of_birth
    ? dateTransformer(establishmentUser?.date_of_birth)
    : undefined,
  full_name: establishmentUser?.full_name || undefined,
  user_roles: establishmentUser?.user_roles.length
    ? transformRolesToOptions(establishmentUser?.user_roles)
    : [],
  status:
    statusOptions.find(({ value }) => value === establishmentUser?.status) ||
    undefined,
  phone: {
    number: establishmentUser?.phone?.number || undefined,
  },
  email: establishmentUser?.email || undefined,
  enrollment_number: establishmentUser?.enrollment_number || undefined,
})
