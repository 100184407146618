import { ClientConsumptionLineItemData } from '@/common/types/client/consumptionLineItem.type';
import { formatCurrency, formatDecimal, formatUnit } from '@/common/utils/formatters';

export class BillingTransactionIdentifiersTransformer {
  static tableTransformer() {
    return (products: ClientConsumptionLineItemData[]) => {
      return products?.map((product) => ({
        ...product,
        name: product.product?.display_name || 'Não informado',
        quantity: product.quantity ? formatUnit(formatDecimal(product.quantity), product.product.unit_for_quantity) : 'Não informado',
        unitValue: parseInt(product.quantity) ? formatCurrency(parseFloat(product.amount) / parseInt(product.quantity)) : '',
        totalValue: product.amount ? formatCurrency(parseFloat(product.amount)) : 'Não informado',
      }));
    };
  }
}
