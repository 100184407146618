import { TicketData } from '@/common/types/support/ticket.type'
import { formatDateTime } from '@/common/utils/formatters'
import { SupportTicketCreator } from '@/features/support/components/SupportTicketCreator'
import { SupportTicketStatus } from '@/features/support/components/SupportTicketStatus'
import { ArrowBigRightIcon } from 'lucide-react'
import { createElement } from 'react'
import { Link } from 'react-router-dom'

export class SupportTicketsTransformer {
  static tableTransformer() {
    return (supportTickets: TicketData[]) => {
      return supportTickets.map((supportTicket) => ({
        status: supportTicket.status
          ? createElement(SupportTicketStatus, { status: supportTicket.status, responsible: supportTicket.responsible })
          : '',
        reference_number: supportTicket.reference_number,
        reason: supportTicket.reason.display_name || '',
        creator: supportTicket.creator ? createElement(SupportTicketCreator, { creator: supportTicket.creator }) : '',
        created_at: supportTicket.created_at ? formatDateTime(new Date(supportTicket.created_at)) : '',
        actions: (
          <Link to={`/support/${supportTicket.id}`}>
            <ArrowBigRightIcon />
          </Link>
        ),
      }))
    }
  }
}
