import { CardType } from '@/common/constants/cards.constant'
import { unFormatUnknownUnit } from '@/common/utils/unFormatters'
import { z } from 'zod'

export const productSchema = z.object({
  product_id: z.string(),
  quantity: z.string().min(1),
  unitary_amount: z.string().optional(),
  amount: z.string(),
  display_name: z.string().optional(),
  unit_for_quantity: z.string().optional(),
})

export const createSchema = z.object({
  card_identifier: z.string({ message: 'Campo obrigatório' }),
  card_id: z.string({ message: 'Equipamento não encontrado' }),
  card_type: z.nativeEnum(CardType).optional(),
  buyer_cpf: z.string().min(1, 'Campo CPF é obrigatório').min(14, 'Use um CPF válido'),
  total_quantity: z.number(),
  total_amount: z.number(),
  current_equipment_usage: z.string().optional(),
  line_items_attributes: z.array(productSchema).min(1, { message: 'Deve haver pelo menos um produto' }),
  consumption_password: z.string({ message: 'Campo obrigatório' }),
}).superRefine((data, ctx) => {
  if (data.card_type === CardType.Fleet && (!data.current_equipment_usage || !unFormatUnknownUnit(data.current_equipment_usage))) {
    ctx.addIssue({
      path: ['current_equipment_usage'],
      code: z.ZodIssueCode.custom,
      message: 'Campo obrigatório',
    })
  }
})

export type ConsumptionValidationSchema = z.infer<typeof createSchema>
