import ListFilters from '@/common/components/ListFilters'
import ListHeader from '@/common/components/ListHeader'
import Table from '@/common/components/Table'
import TextField from '@/common/components/TextField'
import { useRoles } from '@/common/hooks/queries/useRoles'
import useDebounce from '@/common/hooks/useDebounce'
import { useFilterParams } from '@/common/hooks/useFilterParams'
import { Mapper } from '@/common/services/mapper'
import { RoleData } from '@/common/types/roles'
import { defaultColumns } from '@/features/users/constants/columns.constant'
import { useEstablishmentUsers } from '@/features/users/hooks/useEstablishmentUsers'
import { EstablishmentUsersTransformer } from '@/features/users/services/establishmentUsersTransformer'
import {
  EstablishmentUserData,
  EstablishmentUserTableItem,
} from '@/features/users/types/establishmentUsers'
import { SearchIcon, UserIcon } from 'lucide-react'

const List = () => {
  const { filterParams, setFilterParams } = useFilterParams()

  const [searchName, setSearchName] = useDebounce(filterParams.name || '')

  const { data: users, meta, isPending: isPendingEstablishmentUsers} = useEstablishmentUsers({
    filters: {
      page: filterParams.page || 1,
      search: searchName,
      role: filterParams.role?.value || '',
    },
    mapper: Mapper.mapWithTransformer<
      EstablishmentUserData[],
      EstablishmentUserTableItem[]
      >({
      transformer: EstablishmentUsersTransformer.tableTransformer(),
    }),
  })

  const { data: rolesOptions } = useRoles({
    mapper: Mapper.mapToOptions<RoleData>({
      labelFieldName: 'display_name',
      valueFieldName: 'id',
    }),
  })

  return (
    <div>
      <div className="flex flex-col gap-6">
        <ListHeader
          Icon={<UserIcon className="w-10 h-10" strokeWidth={1.5} />}
          title="Usuários"
          createButtonText="Novo usuário"
        />
        <div className="flex flex-col gap-4">
          <TextField
            LeftIcon={<SearchIcon />}
            placeholder="Pesquisar"
            className="rounded-sm shadow-[4px_0px_16px_0px_rgba(0,_0,_0,_0.5)]"
            defaultValue={filterParams.name || ''}
            onChange={(e: any) => {
              setSearchName(e.target?.value)
              setFilterParams((prev) => ({
                ...prev,
                name: e.target?.value,
              }))
            }}
          />
          <ListFilters
            filters={[
              {
                className: 'w-[160px]',
                onChange: (e: any) =>
                  setFilterParams((params) => ({
                    ...params,
                    role: e.value ? e : undefined,
                  })),
                defaultValue: filterParams.role || undefined,
                options: [
                  { label: 'Todos', value: '' },
                  ...(rolesOptions || []),
                ],
                placeholder: 'Tipo de usuário',
              },
            ]}
          />
        </div>
        <Table
          columns={defaultColumns}
          isPendingData={isPendingEstablishmentUsers}
          page={filterParams.page || 1}
          rows={users}
          totalPages={meta.total_pages}
          totalCount={meta.total}
          hasPagination
          setPage={(p) => setFilterParams((params) => ({ ...params, page: p }))}
        />
      </div>
    </div>
  )
}

export default List
