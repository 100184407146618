import { useEffect, useState } from "react";
import { AddressData } from "../types/address";
import useZipcodeData from "./queries/useZipcodeData";

interface useAddressFormProps {
  initialAddress: AddressData;
  onSetAddress?: (address: AddressData, valid: boolean) => void;
}

const useAddressForm = ({ initialAddress, onSetAddress }: useAddressFormProps) => {
  const [validZipcode, setValidZipcode] = useState<boolean>(true);
  const [address, setAddress] = useState<AddressData>(initialAddress);

  const setReponseData = (data: AddressData) => {
    setAddress({
      ...address,
      street: data.street,
      neighborhood: data.neighborhood,
      city: data.city,
      city_id: data.city?.id,
    });

    setValidZipcode(!!data.street);
  };

  const setAddressField = (key: keyof AddressData, value: string) => {
    setAddress({
      ...address,
      [key]: value,
    });
  };

  const { mutate: getZipcodeData, isPending } = useZipcodeData({
    onSuccess: (response) => {
      const data = response.data.data as AddressData;

      setReponseData(data);
    },
    onError() {
      setReponseData({});
    },
  });

  const setZipcodeField = (zipcode: string) => {
    setAddressField("zipcode", zipcode);
    getZipcodeData(zipcode);
  }

  useEffect(() => {
    if (onSetAddress && !isPending) {
      onSetAddress(address, validZipcode);
    }
  }, [JSON.stringify(address)]);

  return { setZipcodeField, setAddressField, address, isSearchZipcodePending: isPending }
};

export default useAddressForm;