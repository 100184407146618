import usePrivateAxios from '@/common/api/usePrivateAxios'
import { getRailsUpdate } from '@/common/utils/getRailsUpdate'
import { mapToDeletion } from '@/common/utils/railsHelpers'
import { transformNewRoles } from '@/common/utils/roleTransformers'
import { removeSpecialCharacters } from '@/common/utils/unFormatters'
import { EstablishmentUserValidationSchema } from '@/features/users/schemas/create.schema'
import { EstablishmentUserData } from '@/features/users/types/establishmentUsers'
import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'

interface UseUpdateEstablishmentUserProps {
  establishmentUserId?: string
  establishmentUserData?: EstablishmentUserData
  onSuccess: (response: AxiosResponse) => void
  onError: (error: AxiosError) => void
}

export const useUpdateEstablishmentUser = ({
  establishmentUserId,
  establishmentUserData,
  onSuccess,
  onError,
}: UseUpdateEstablishmentUserProps) => {
  const privateAxios = usePrivateAxios()

  return useMutation({
    mutationKey: ['updateEstablishmentUser'],
    mutationFn: async (form: EstablishmentUserValidationSchema) => {
      const payload = {
        ...form,
        cpf: removeSpecialCharacters(form.cpf),
        date_of_birth: form.date_of_birth || null,
        user_roles_attributes: getRailsUpdate(
          establishmentUserData?.user_roles || [],
          form?.user_roles || [],
          (x) => x.role.id,
          (x) => x.value,
          mapToDeletion,
          transformNewRoles,
        ),
        status: form?.status?.value || 'enabled',
        email: form.email || null,
        phone_attributes: { number: form?.phone?.number ? removeSpecialCharacters(form.phone.number) : null }
      }

      return privateAxios.put(`/users/${establishmentUserId}`, {
        establishment_user: payload,
      })
    },
    onSuccess,
    onError,
  })
}
