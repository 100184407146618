import { CardDetailsData } from '@/common/types/cards/details.type'
import React, { useEffect } from 'react'
import { UseFormSetValue } from 'react-hook-form'
import { cardDetailsComponents } from '../constants/cardDetails.constant'
import { ConsumptionValidationSchema } from '../schemas/create.schema'

interface EquipmentInfoProps {
  cardDetails?: CardDetailsData
  setValue?: UseFormSetValue<ConsumptionValidationSchema>
}

const EquipmentInfo = ({ cardDetails, setValue }: EquipmentInfoProps) => {
  useEffect(() => {
    if (cardDetails && cardDetails?.id) {
      setValue?.('card_id', cardDetails?.id)
      setValue?.('card_type', cardDetails?.type)
    }
  }, [cardDetails?.id])

  if (!cardDetails?.id) {
    return <span className="text-danger-soft text-sm">Cartão não encontrado</span>
  }

  return React.createElement(cardDetailsComponents[cardDetails?.type as keyof typeof cardDetailsComponents], {
    cardDetails,
  })
}

export default EquipmentInfo
