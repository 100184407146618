import Box from '@/common/components/Box'
import CardDetailCompanyInformation from '../CardDetailCompanyInformation'
import { CardDetailsData } from '@/common/types/cards/details.type'

type PersonalCardDetailsProps = {
  cardDetails: CardDetailsData
}

const PersonalCardDetails = ({ cardDetails }: PersonalCardDetailsProps) => {
  return (
    <div className="flex flex-col gap-4">
      <CardDetailCompanyInformation companyBranch={cardDetails.equipment?.branch} />

      <Box className="flex flex-col items-start rounded-lg gap-2 p-6 py-5">
        <div className="flex gap-4 items-center">
          <p className="font-light italic">Nome do Dono:</p>

          <p className="text-lg uppercase">{cardDetails?.owner?.full_name}</p>
        </div>
      </Box>
    </div>
  )
}

export default PersonalCardDetails
