import usePrivateAxios from '@/common/api/usePrivateAxios';
import { defaultTransformerResponse } from '@/common/constants/defaultTransformerResponse.constant';
import { Mapper } from '@/common/services/mapper';
import { MetaResponse } from '@/common/types/metaResponse';
import { useQuery } from '@tanstack/react-query';

type UseBillingTransactionIdentifiersProps<T> = {
  identifier: string;
  mapper?: (data: any) => T;
};

export const useBillingTransactionIdentifiers = <T,>({
  identifier = '',
  mapper = Mapper.default<T>(),
}: UseBillingTransactionIdentifiersProps<T>) => {
  const privateAxios = usePrivateAxios();

  const { data: { data, meta } = defaultTransformerResponse<T>(), ...rest } = useQuery({
    queryKey: ['billingTransactionIdentifiers', identifier],
    queryFn: () => privateAxios.get(`/billing/transaction_identifiers/${identifier}`),
    select: (data: { data: { data: any[]; meta: MetaResponse; }; }) => {
      return {
        data: mapper(data?.data?.data),
        meta: data?.data?.meta,
      };
    },
    enabled: !!identifier,
    retry: false,
  });

  return { data, meta, ...rest };
};
