import Button from '@/common/components/Button'
import Table from '@/common/components/Table'
import { formatCurrency } from '@/common/utils/formatters'
import { ChevronLeft } from 'lucide-react'
import { Dispatch, SetStateAction } from 'react'
import { useFormContext } from 'react-hook-form'
import { defaultColumns } from '../constants/columns.constants'
import { ConsumptionValidationSchema } from '../schemas/create.schema'
import { CardType } from '@/common/constants/cards.constant'
import { UnitTextField } from '@/common/components/UnitTextField';
import { unitStrategy } from '@/common/constants/unitStrategies.contants';

interface TabResumeProps {
  activeTab: number;
  handleTabChange: (tabIndex: number) => void;
  openPasswordModal: Dispatch<SetStateAction<boolean>>;
  isPending: boolean;
}

const TabResume = ({ activeTab, handleTabChange, openPasswordModal, isPending }: TabResumeProps) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<ConsumptionValidationSchema>();

  const watchTotalAmount = watch('total_amount');
  const watchProducts = watch('line_items_attributes');
  const card_type = watch('card_type');

  return (
    <>
      <div className="flex flex-col gap-6 grow max-h-[calc(100vh-192px)] overflow-hidden">
        <div
          className="flex w-max items-center gap-3 cursor-pointer rounded py-2"
          onClick={() => handleTabChange(activeTab - 1)}
        >
          <ChevronLeft />

          <span>Voltar para produtos</span>
        </div>

        <div className="flex gap-6 grow">
          {card_type === CardType.Fleet && (
            <div className="basis-1/2 h-full flex flex-col gap-4">
              <p className="text-[20px] font-bold">Confirmação de segurança</p>

              <UnitTextField
                decimals={0}
                unit={unitStrategy.kilometers}
                maxValue={9999999}
                label="Quilometragem atual do equipamento"
                errorMessage={errors?.current_equipment_usage?.message}
                {...register('current_equipment_usage')}
              />
            </div>
          )}

          <div className="basis-1/2 h-full flex flex-col gap-4 ml-auto">
            <p className="text-[20px] font-bold">Resumo da transação</p>

            <Table
              columns={defaultColumns}
              isPendingData={isPending}
              page={1}
              rows={watchProducts}
              totalPages={1}
              totalCount={watchProducts.length}
            />
          </div>
        </div>
      </div>

      <div className="flex items-center justify-end gap-4">
        <div>
          <p className="text-[14px] font-light italic">Valor total</p>

          <p className="text-[18px] font-bold">{formatCurrency(watchTotalAmount || 0)}</p>
        </div>

        <div>
          <Button type="button" onClick={() => openPasswordModal(true)} disabled={isPending}>
            Efetuar transação
          </Button>
        </div>
      </div>
    </>
  );
};

export default TabResume;
