import Button from '@/common/components/Button';
import Modal from '@/common/components/Modal';
import { useProducts } from '@/common/hooks/queries/useProducts';
import useDebounce from '@/common/hooks/useDebounce';
import { Mapper } from '@/common/services/mapper';
import { ProductData } from '@/common/types/product.type';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { productPriceSchema, ProductPriceValidationSchema } from '../schemas/productPrice.schema';
import SelectController from '@/common/components/SelectController';
import useCreateProductPrice from '../hooks/useCreateProductPrice';
import { FormTransformers } from '@/common/utils/formTransformers';
import { useQueryClient } from '@tanstack/react-query';
import { ProductPricesTransformer } from '../services/productPricesTransformer';
import { CurrencyTextField } from '@/common/components/CurrencyTextField';

const NewProductPriceModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const [searchProduct, setSearchProduct] = useDebounce('');

  const { data: productsOptions } = useProducts({
    filters: { search: searchProduct },
    mapper: Mapper.mapToOptions<ProductData>({ labelFieldName: 'display_name', valueFieldName: 'id' }),
  });

  const { control, handleSubmit, setError, reset, formState: { errors } } = useForm<ProductPriceValidationSchema>({
    resolver: zodResolver(productPriceSchema),
  });

  const { mutate: createProductPrice, isPending } = useCreateProductPrice({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['productsPrices'] });
      setIsOpen(false);
    },
    onError: (error) => {
      FormTransformers.errorsTransformer<ProductPriceValidationSchema>({ setError })(
        error,
        ProductPricesTransformer.errorsTransformer()
      );
    },
  });

  const onSubmit: SubmitHandler<ProductPriceValidationSchema> = async (form) => createProductPrice(form);

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      Trigger={(
        <Button className="text-nowrap">
          Novo produto
        </Button>
      )}
      title="Adicionar novo produto"
      footer="Confirmar adição e salvar alteração"
      body={(
        <form className="flex flex-col gap-4">
          <SelectController
            control={control}
            name="product"
            className="text-white-800"
            label="Produto"
            onInputChange={setSearchProduct}
            options={productsOptions || []}
            markAsRequired
            errorMessage={errors?.product?.message || ' '}
            isLoading={isPending}
          />

          <Controller
            name="value"
            control={control}
            disabled={isPending}
            render={({ field }) => (
              <CurrencyTextField
                label="Valor do produto"
                markAsRequired
                className="text-white-800"
                errorMessage={errors.value?.message}
                isLoading={isPending}
                {...field}
              />
            )}
          />
        </form>
      )}
      onConfirm={handleSubmit(onSubmit)}
    />
  );
};

export default NewProductPriceModal;