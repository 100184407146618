import Box from "@/common/components/Box";
import ListHeader from "@/common/components/ListHeader";
import TextArea from "@/common/components/TextArea";
import TextField from "@/common/components/TextField";
import { SquareX } from "lucide-react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { refundSchema, RefundValidationSchema } from "../../schemas/refund.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import useCreateRefund from "../../hooks/useCreateRefund";
import { useBillingTransactionIdentifiers } from "../../hooks/useBillingTransactionIdentifiers";
import { BillingTransactionIdentifier, BillingTransactionIdentifierErrorResponseData } from "@/common/types/billing/transactionIdentifier.type";
import { TransactionInformation } from "../../components/transactionInformation";
import { formatCurrency } from "@/common/utils/formatters";
import Button from "@/common/components/Button";
import Spinner from "@/common/components/Spinner";
import ModalPassword from "@/common/components/ModalPassword";
import { useEffect, useState } from "react";
import ModalSuccess from "../../components/ModalSuccess";
import ModalError from "../../components/ModalError";
import useDebounce from "@/common/hooks/useDebounce";

const RefundCreate = () => {
  const [modalPasswordIsOpen, setModalPasswordIsOpen] = useState(false);
  const [modalSuccessData, setModalSuccessData] = useState<BillingTransactionIdentifier | boolean>(false);
  const [modalErrorData, setModalErrorData] = useState<BillingTransactionIdentifierErrorResponseData | boolean>(false);

  const formMethods = useForm<RefundValidationSchema>({
    resolver: zodResolver(refundSchema),
  });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = formMethods;

  const {
    mutate: createRefund,
    isPending: createRefundIsPending,
  } = useCreateRefund({
    onSuccess: (response) => {
      setModalSuccessData(response.data.data);
    },
    onError: (error) => {
      setValue('consumption_password', '');
      setModalErrorData(error);
    },
  });

  const onSubmit: SubmitHandler<RefundValidationSchema> = () => setModalPasswordIsOpen(true);

  const watchEventId = watch('transaction_identifier');

  const [transactionIdentifierDebounced, setTransactionIdentifierDebounced] = useDebounce('');

  useEffect(() => {
    setTransactionIdentifierDebounced(watchEventId);
  }, [watchEventId]);

  const { data: transactionIdentifiers, isLoading: transactionIdentifiersIsLoading, isError: transactionIdentifiersIsError, isPending: transactionIdentifiersIsPending } =

    useBillingTransactionIdentifiers<BillingTransactionIdentifier>({
      identifier: transactionIdentifierDebounced || '',
    });

  return (
    <FormProvider {...formMethods}>
      <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex">
          <ListHeader
            Icon={<SquareX className="w-10 h-10" strokeWidth={1.5} />}
            title="Estorno"
          />
        </div>

        <Box className="h-[calc((100vh-104px-1.5rem))] flex gap-6">
          <div className="grow w-1/2 flex flex-col gap-6">
            <p className="text-[20px] font-bold">Confirmação de segurança</p>

            <TextField
              label="N. da Transação"
              {...register('transaction_identifier')}
              errorMessage={transactionIdentifiersIsError ? 'Transação não encontrada' : ' '}
              isLoading={transactionIdentifiersIsLoading}
              disabled={createRefundIsPending}
            />

            {transactionIdentifiers.id && (
              <div className="flex flex-col gap-2">
                <p className="text-[18px]">Dados para estorno</p>

                <TextArea
                  rows={5}
                  placeholder="Motivo do estorno"
                  {...register('reason')}
                  errorMessage={errors?.reason?.message || ' '}
                />
              </div>
            )}
          </div>

          <div className="grow w-1/2 flex flex-col gap-6">
            {transactionIdentifiers.id && (
              <>
                <p className="text-[20px] font-bold">Resumo da transação</p>

                <TransactionInformation 
                  transactionIdentifiers={transactionIdentifiers} 
                  transactionIdentifiersIsPending={transactionIdentifiersIsPending}
                />

                <div className="flex gap-4 self-end mt-auto">
                  <div className="flex flex-col items-end">
                    <p className="font-light text-sm italic leading-4">Valor total</p>
                    <p className="font-bold text-lg leading-5">
                      {formatCurrency(transactionIdentifiers?.eventable?.total_amount || 0)}
                    </p>
                  </div>

                  <Button
                    variant="error"
                    type="submit"
                    disabled={!transactionIdentifiers.id || createRefundIsPending}
                  >
                    {createRefundIsPending ? <Spinner className="h-6 mx-[23px] fill-white-800" /> : 'Estornar transação'}
                  </Button>
                </div>
              </>
            )}
          </div>
        </Box>
      </form>

      <ModalPassword
        fieldName="consumption_password"
        isOpen={modalPasswordIsOpen}
        setIsOpen={setModalPasswordIsOpen}
        submit={() => createRefund(getValues())}
      />

      <ModalSuccess
        data={modalSuccessData as BillingTransactionIdentifier}
        setData={setModalSuccessData}
        clearForm={() => reset()}
      />

      <ModalError
        data={modalErrorData as BillingTransactionIdentifierErrorResponseData}
        setData={setModalErrorData}
      />
    </FormProvider>
  );
};

export default RefundCreate;
