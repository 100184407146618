import MaskedTextField from '@/common/components/MaskedTextField'
import TextField from '@/common/components/TextField'
import { useCardDetails } from '@/common/hooks/queries/useCardDetails'
import useDebounce from '@/common/hooks/useDebounce'
import { useEffect } from 'react'
import { FieldErrors, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { ConsumptionValidationSchema } from '../schemas/create.schema'
import EquipmentInfo from './EquipmentInfo'

interface EssentialInformationFormProps {
  register: UseFormRegister<ConsumptionValidationSchema>
  setValue: UseFormSetValue<ConsumptionValidationSchema>
  clearForm: () => void
  watch: UseFormWatch<ConsumptionValidationSchema>
  errors?: FieldErrors<ConsumptionValidationSchema>
}

const EssentialInformationForm = ({ register, setValue, clearForm, errors, watch }: EssentialInformationFormProps) => {
  const cardIdentifierFromForm = watch('card_identifier')

  const [debouncedCardIdentifier, setDebouncedCardData] = useDebounce(cardIdentifierFromForm || '')

  const { data: cardDetails, isLoading: isLoadingCard } = useCardDetails(debouncedCardIdentifier || '')

  useEffect(() => {
    if (debouncedCardIdentifier) setValue('card_identifier', debouncedCardIdentifier)
  }, [debouncedCardIdentifier])

  useEffect(() => {
    if (!cardDetails?.id) return clearForm()
  }, [cardDetails?.id])

  return (
    <div className="flex flex-col gap-6 h-full">
      <p className="text-[20px] font-bold">Insira ou digite o código do cartão</p>

      <TextField
        label="Código do cartão ou Placa do equipamento"
        errorMessage={errors?.card_id?.message}
        onChange={(e) => setDebouncedCardData((e.target as HTMLInputElement).value)}
        isLoading={isLoadingCard}
      />

      {!isLoadingCard && !!debouncedCardIdentifier && (
        <>
          <EquipmentInfo cardDetails={cardDetails} setValue={setValue} />

          {cardDetails?.id && (
            <MaskedTextField
              mask="000.000.000-00"
              label="Usuário"
              markAsRequired
              placeholder="Digite o CPF"
              errorMessage={errors?.buyer_cpf?.message}
              {...register('buyer_cpf')}
            />
          )}
        </>
      )}
    </div>
  )
}

export default EssentialInformationForm
