import { BranchData } from '@/common/types/cards/details.type'
import { ChevronsRight } from 'lucide-react'

type CardDetailCompanyInformationProps = {
  companyBranch?: BranchData
}

const CardDetailCompanyInformation = ({ companyBranch }: CardDetailCompanyInformationProps) => {
  return (
    <div className="flex items-center gap-2">
      {companyBranch?.general_information?.company_logo && (
        <img src={companyBranch?.general_information?.company_logo?.url} className="w-[36px] h-[36px] rounded-full" />
      )}

      <p className="text-[22px] font-bold">
        {companyBranch?.general_information?.fantasy_name || companyBranch?.general_information?.official_name}
      </p>

      <ChevronsRight color="#ffffffb3" />

      <p className="text-xl italic font-light">{companyBranch?.display_name}</p>
    </div>
  )
}

export default CardDetailCompanyInformation
