import { AddressData } from "@/common/types/address";
import MaskedTextField from "../MaskedTextField";
import { unFormatCnpj as unFormatMask } from "@/common/utils/unFormatters";
import TextField from "../TextField";
import { FieldErrors } from "react-hook-form";
import { AddressValidationSchema } from "@/common/schemas/address.schema";
import useAddressForm from "@/common/hooks/useAddressForm";
import AddressMapImage from "../AddressMapImage";

export interface AddressFormProps {
  initialAddress: AddressData;
  onSetAddress?: (address: AddressData, valid: boolean) => void;
  disableFields?: boolean;
  errors: FieldErrors<AddressValidationSchema>;
  markFieldsAsRequired?: boolean;
}

const AddressForm = ({
  initialAddress,
  onSetAddress,
  disableFields,
  errors,
  markFieldsAsRequired,
}: AddressFormProps) => {
  const {
    address,
    setZipcodeField,
    setAddressField,
    isSearchZipcodePending
  } = useAddressForm({ initialAddress, onSetAddress });

  return (
    <div className="flex gap-6">
      <div className="w-1/2 flex flex-col gap-4">
        <MaskedTextField
          mask="00000-000"
          label="CEP"
          defaultValue={address?.zipcode|| initialAddress?.zipcode || ""}
          errorMessage={errors?.zipcode?.message || undefined}
          onBlur={(e) => setZipcodeField(unFormatMask((e.target as HTMLInputElement).value))}
          disabled={disableFields}
          RightIcon={isSearchZipcodePending ? <div className="w-4 h-4 border-4 border-dashed rounded-full animate-spin border-blue-800 mb-1 mr-1" /> : <></>}
          markAsRequired={markFieldsAsRequired}
        />

        <div className="flex gap-2">
          <TextField
            label="Logradouro"
            className="w-3/4"
            defaultValue={address?.street|| initialAddress?.street || ""}
            value={address?.street || ""}
            onChange={(e) => setAddressField("street", (e.target as HTMLInputElement).value)}
            errorMessage={errors?.street?.message || undefined}
            disabled={disableFields}
            markAsRequired={markFieldsAsRequired}
          />

          <MaskedTextField
            label="Número"
            name="number"
            mask="00000"
            className="w-1/4"
            defaultValue={address?.number || initialAddress?.number || ""}
            value={address?.number || ""}
            onChange={(e) => setAddressField("number", (e.target as HTMLInputElement).value)}
            errorMessage={errors?.number?.message || undefined}
            disabled={disableFields}
            markAsRequired={markFieldsAsRequired}
          />
        </div>

        <TextField
          label="Município"
          defaultValue={address?.city?.display_name || initialAddress?.city?.display_name || ""}
          value={address.city?.display_name || ""}
          errorMessage={errors?.city_id?.message || undefined}
          disabled
          markAsRequired={markFieldsAsRequired}
        />

        <TextField
          label="Bairro"
          maxLength={64}
          defaultValue={address?.neighborhood || initialAddress?.neighborhood || ""}
          value={address?.neighborhood || ""}
          errorMessage={errors?.neighborhood?.message || undefined}
          onChange={(e) => setAddressField("neighborhood", (e.target as HTMLInputElement).value)}
          disabled={disableFields}
          markAsRequired={markFieldsAsRequired}
        />

        <TextField
          label="Estado"
          defaultValue={address?.city?.state?.display_name || initialAddress?.city?.state?.display_name || undefined}
          value={address?.city?.state?.display_name || undefined}
          disabled
          markAsRequired={markFieldsAsRequired}
        />

        <TextField
          label="Complemento"
          defaultValue={address?.complement || initialAddress?.complement || ""}
          value={address.complement || ""}
          onChange={(e) => setAddressField("complement", (e.target as HTMLInputElement).value)}
          disabled={disableFields}
        />
      </div>

      <AddressMapImage address={initialAddress || address } />
    </div>
  );
};

export default AddressForm;