type CardDetailFieldPairProps = {
  label: string
  value: string
}

const CardDetailFieldPair = ({ label, value }: CardDetailFieldPairProps) => {
  return (
    <div className="flex gap-2 items-center">
      <p className="text-sm font-light italic">{label}:</p>

      <p className="text-sm leading-none">{value}</p>
    </div>
  )
}

export default CardDetailFieldPair
