import { useMemo } from 'react'

export const useAppDetails = () => {
  const commitSha = useMemo(() => {
    const version = import.meta.env.VITE_GITHUB_SHA as string
    return version ? version.slice(0, 7) : 'Local'
  }, [import.meta.env.VITE_GITHUB_SHA])

  return { commitSha }
}
