import Box from '@/common/components/Box'
import { cardTypeTranslation } from '@/common/constants/cards.constant'
import { formatCardNumber } from '@/common/utils/formatters'
import CardDetailCompanyInformation from '../CardDetailCompanyInformation'
import CardDetailFieldPair from '../CardDetailFieldPair'
import { CardDetailsData, EquipmentData } from '@/common/types/cards/details.type'

type FleetCardDetailsProps = {
  cardDetails: CardDetailsData
}

const FleetCardDetails = ({ cardDetails }: FleetCardDetailsProps) => {
  const field_for_display = cardDetails?.equipment?.model?.category?.field_for_display

  return (
    <div className="flex flex-col gap-4">
      <CardDetailCompanyInformation companyBranch={cardDetails.equipment.branch} />

      <Box className="flex flex-col items-start rounded-lg gap-2 p-6 py-5">
        <div className="flex gap-4 items-center">
          <p className="font-light italic">Placa do veiculo:</p>

          <p className="text-lg uppercase">
            {cardDetails?.equipment?.[(field_for_display || 'license_plate') as keyof EquipmentData]}
          </p>
        </div>

        <div className="bg-gray-disabled h-[2px] w-5 rounded" />

        <CardDetailFieldPair label="Cartão" value={formatCardNumber(cardDetails?.last_four_digits)} />

        <CardDetailFieldPair
          label="Tipo de Cartão"
          value={cardTypeTranslation[cardDetails?.type as keyof typeof cardTypeTranslation]}
        />

        <CardDetailFieldPair label="Modelo" value={cardDetails?.equipment?.model?.display_name} />
      </Box>
    </div>
  )
}

export default FleetCardDetails
