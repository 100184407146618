import { optionsObject as billOptionsObject } from "../constants/billsStatusOptions.constant";
import { formatCurrency, formatDate } from "@/common/utils/formatters";
import { Download } from "lucide-react";
import { BillData } from "../types/bills.type";

export class BillsTransformer {
  static tableTransformer() {
    return (bills: BillData[]) => {
      return bills.map((bill) => ({
        ...bill,
        status: billOptionsObject[bill.status as keyof typeof billOptionsObject],
        credit_info: bill?.granted_credit ? formatCurrency(parseFloat(`${bill?.granted_credit}`)) : undefined,
        total_amount: bill?.total_amount ? formatCurrency(parseFloat(`${bill?.total_amount}`)) : "Não informado",
        events_count: `${bill.events_count || 0} transações`,
        opened_at: formatDate(new Date(bill.opened_at)),
        closed_at: formatDate(new Date(bill.closed_at)),
        due_at: formatDate(new Date(bill.due_at)),
        actions: <Download />,
      }));
    };
  }
}