import { twMerge } from "tailwind-merge"

export interface IconButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  Icon: JSX.Element
}

const IconButton = ({
  Icon, 
  className,
  ...props
}: IconButtonProps) => {
  return (
    <div className={twMerge("w-10 h-10 p-2 cursor-pointer hover:bg-neutral-op-8 rounded-md flex justify-center items-center", className)} {...props}>
      {Icon}
    </div>
  )
}

export default IconButton