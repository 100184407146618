import { isValidDate } from "@/common/utils/dateValidator"
import { unities } from "../constants/unities.constants"
import { numberToFixedHalfDown } from "./numberToFixedHalfDown";

export const formatCurrency = (value: number) => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  })
  return formatter.format(value)
}

export const formatPercentage = (value: number) => {
  return `${value * 100} %`
}

export const formatCpf = (cpf: string) => {
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export const formatCnpj = (cnpj: string) => {
  return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
}

export const formatDate = (date: Date) => {
  return new Intl.DateTimeFormat('pt-BR').format(date);
}

export const formatDateTime = (date: Date) => {
  if (!isValidDate(date)) return '';

  const formattedDate = formatDate(date);
  const formattedMinutes = String(date.getMinutes()).padStart(2, '0');

  return `${formattedDate} às ${date.getHours()}:${formattedMinutes}`;
};

export const formatUnit = (value: string | number, unit: string) => {
  return `${value}${unities[unit]}`
}

export const formatCardNumber = (cardNumber: string | number) => {
  const cardNumberStr = cardNumber.toString();
  const maskedPart = cardNumberStr.padStart(16, '*');
  return maskedPart.replace(/(.{4})/g, '$1 ').trim();
}

export const formatDecimal = (value: string | number, decimals = 3) => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
  });

  return formatter.format(Number(numberToFixedHalfDown(Number(value), decimals)));
}

export const formatPhoneNumber = (phone: string) => {
  return phone.replace(/^(\d{2})(\d{2})(\d{5}|\d{4})(\d{4})$/, '+$1 ($2) $3-$4')
}
