import LogRequestShow from '@/common/components/LogRequestShow';
import { ChevronLeftIcon } from 'lucide-react';
import { Link, useParams } from 'react-router-dom';
import { useEstablishmentUser } from '../../hooks/useEstablishmentUser';

const ActionsHistoryShow = () => {
  const { id: userId } = useParams();

  const { data: establishmentUserData } = useEstablishmentUser({
    id: userId || '',
  });

  if (!userId) {
    return null;
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <div className="flex gap-4 items-center">
          <Link to={`/users/${userId}/actionsHistory`} className="cursor-pointer">
            <ChevronLeftIcon size={40} strokeWidth={1} />
          </Link>

          <h2 className="text-2xl font-bold">
            {establishmentUserData?.full_name}
          </h2>
        </div>
      </div>

      <div className="h-[calc(100vh-64px-32px-40px)]">
        <LogRequestShow />
      </div>
    </div>
  );
};

export default ActionsHistoryShow;
