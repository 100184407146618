import { Props } from "react-select";
import Select from "../Select";
import { Control, Controller, Path } from "react-hook-form";

export type SelectControllerProps<T extends {}>  = {
  control: Control<T>
  name: Path<T>
  label?: string
  markAsRequired?: boolean
  className?: string
  inputClassName?: string 
  errorMessage?: string
  triggerOnChange?: (e: any) => void
  options: {label: string | JSX.Element, value: string | number}[]
} & Props

const SelectController = <T extends {}>({
  control,
  name,
  label,
  markAsRequired,
  className,
  inputClassName,
  errorMessage,
  triggerOnChange,
  options,
  ...props
}: SelectControllerProps<T>) => {
  return  (
    <div className={className}>
      <Controller 
        name={name}
        control={control}
        render={({ field: { onChange, ...field } }) => (
          <Select 
            {...field}
            label={label}
            markAsRequired={markAsRequired}
            className={inputClassName}
            options={options}
            onChange={(e) => {
              onChange(e);
              triggerOnChange && triggerOnChange(e);
            }}
            {...props}
          />
        )}
      />
      {errorMessage ? <span className="text-danger-soft text-sm">{errorMessage}</span> : null}
    </div>
  )
}

export default SelectController