import Button from '@/common/components/Button';
import { ShowHeader } from '@/common/components/ShowHeader';
import Building from '@/common/Layout/building';
import { Link } from 'react-router-dom';
import { useMineCompany } from '../../hooks/useMineCompany';
import Box from '@/common/components/Box';

const Company = () => {
  const { data: company } = useMineCompany();

  return (
    <div>
      <ShowHeader
        logoUrl={company?.general_information.company_logo?.url}
        displayName={company?.general_information.fantasy_name}
        buttons={(
          <Link to="/company/mine">
            <Button variant="outline-disabled">
              Ver perfil
            </Button>
          </Link>
        )}
      />

      <Box className="flex flex-col gap-6 h-[calc(100vh-130px)] overflow-y-scroll">
        <Building />
      </Box>
    </div>
  );
};

export default Company;