import { optionsObjectToArray } from "../utils/optionsObjectToArray";

export const optionsObject = {
  weekly: "Semanal",
  fortnightly: "Quinzenal",
  monthly: "Mensal",
}

export const options = optionsObjectToArray(optionsObject);

export const keyToObject = (key: string) => ({label: optionsObject[key as keyof typeof optionsObject], value: key})
