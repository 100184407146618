import { EstablishmentUserData } from '@/features/users/types/establishmentUsers'
import { ArrowBigRightIcon } from 'lucide-react'
import { Link } from 'react-router-dom'

export class EstablishmentUsersTransformer {
  static tableTransformer() {
    return (users: EstablishmentUserData[]) => {
      return users.map((user) => ({
        ...user,
        role: !!user?.user_roles?.length
          ? user?.user_roles
              ?.map((userRole) => userRole.role.display_name)
              .join(', ') || 'Não informado'
          : 'Não informado',
        fullName: user.full_name,
        actions: (
          <Link to={`/users/${user.id}`}>
            <ArrowBigRightIcon />
          </Link>
        ),
      }))
    }
  }
}
