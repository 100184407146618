import { useEffect, useState } from "react";

export type useDebounceProps = (
  initial: string,
  delay?: number
) => [string, (x: string) => void]

const useDebounce: useDebounceProps = (initial, delay= 500) => {
  const [instant, setInstant] = useState(initial);
  const [delayed, setDelayed] = useState(initial)
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelayed(instant)
    }, delay)

    return () => clearTimeout(timeoutId)
  }, [instant])

  return [delayed, setInstant]
};

export default useDebounce