export const currencyMask = (max: number, addMoneySymbol = true, includeCentsIfUserDoesntTypeIt = true) => {
  return [{
    mask: `${addMoneySymbol ? 'R$' : ''} num`,
    blocks: {
      num: {
        mask: Number,
        max: max,
        thousandsSeparator: '.',
        padFractionalZeros: includeCentsIfUserDoesntTypeIt,
        min: 0,
        radix: includeCentsIfUserDoesntTypeIt ? ',' : undefined, // The decimal separator
        scale: includeCentsIfUserDoesntTypeIt ? 2 : 0, // Number of decimal places
        signed: false,
        normalizeZeros: false, // Keeps the zeros in the decimal part
      }
    }
  }]
}

export const percentageMask = [{
  mask: 'num %',
  lazy: false,
  blocks: {
    num: {
      mask: Number,
      max: 100,
      min: 0
    }
  }
}]

export const nameMask = [{
  mask: 'char',
  lazy: false,
  blocks: {
    char: {
      mask: /^[a-záàâãéèêíïóôõöúçñ\s]+$/i,
    }
  }
}]

export const phoneMask = [
  '+{55} (00) 0000-0000',
  '+{55} (00) 00000-0000'
]

export const cnpjMask = '00.000.000/0000-00'

export const dateMask = {
  mask: Date,
  min: new Date(1900, 0, 1),
  max: new Date(),
  lazy: false
}

export const customUnitMask = (unit: string, max?: number, decimal = false, decimals?: number) => {
  return [{
    mask: `num ${unit}`,
    lazy: false,
    blocks: {
      num: {
        mask: Number,
        max: max || 999999,
        min: 0,
        padFractionalZeros: decimal,
        radix: decimal ? ',' : undefined,
        scale: decimal ? (decimals || 3) : 0,
      }
    }
  }]
}