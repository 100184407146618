import { AxiosError } from "axios";
import { FieldValues, Path, UseFormSetError } from "react-hook-form";
import { ErrorsMapper } from "../services/errorsMapper";

export class FormTransformers {
  static errorsTransformer<T extends FieldValues>({ setError }: { setError: UseFormSetError<T>; }) {
    return (
      error: AxiosError, 
      transformer: (errors: Record<string, string[]>) => any[] = ErrorsMapper.default()
    ) => {
      const { errors } = error?.response?.data as { errors: Record<string, string[]>; };

      if (!errors || !Object.keys(errors).length) {
        return;
      }

      const transformedErrors = transformer(errors);

      if (transformedErrors.length) {
        transformedErrors.map((error) => {
          const key = Object.keys(error)?.[0];
          setError(key as Path<T>, { message: error[key] });
        });
      }
    };
  }
}