import { useEffect } from 'react';
import { privateAxios } from './axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../stores/auth';
import { getCompanyDomain } from '../services/companyDomainGetSet';

const usePrivateAxios = () => {
  const navigate = useNavigate();
  const { accessToken, updateAccessToken } = useAuth();

  useEffect(() => {
    const companyDomain = getCompanyDomain();

    const requestIntercept = privateAxios.interceptors.request.use(
      config => {
        if (companyDomain && !config.baseURL?.includes(companyDomain)) {
          const insertIndex = Number(config.baseURL?.indexOf("//")) + 2;

          config.baseURL = `${config.baseURL?.slice(0, insertIndex)}${companyDomain}.${config.baseURL?.slice(insertIndex)}`;
        }

        if (!config.headers['Authorization']) {
          config.headers['Authorization'] = accessToken;
        }

        return config;
      },
      error => Promise.reject(error)
    );

    const responseIntercept = privateAxios.interceptors.response.use(
      response => response,
      async (error) => {
        if (error.response.status === 401) {
          localStorage.removeItem('access-token');
          updateAccessToken('');
        }
        
        return Promise.reject(error);
      }
    );
    return () => {
      privateAxios.interceptors.response.eject(responseIntercept);
      privateAxios.interceptors.request.eject(requestIntercept);
    };
  }, [accessToken, navigate]);

  return privateAxios;
};

export default usePrivateAxios;