import { useEffect } from 'react';
import { publicAxios } from './axios';
import { getCompanyDomain } from '../services/companyDomainGetSet';

const useCompanyAxios = () => {
  useEffect(() => {
    const companyDomain = getCompanyDomain();

    const requestIntercept = publicAxios.interceptors.request.use(
      config => {
        if (companyDomain && !config.baseURL?.includes(companyDomain)) {
          const insertIndex = Number(config.baseURL?.indexOf("//")) + 2;

          config.baseURL = `${config.baseURL?.slice(0, insertIndex)}${companyDomain}.${config.baseURL?.slice(insertIndex)}`;
        }

        return config;
      },
      error => Promise.reject(error)
    );

    return () => {
      publicAxios.interceptors.request.eject(requestIntercept);
    };
  }, []);

  return publicAxios;
};

export default useCompanyAxios;