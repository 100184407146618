export const getFilenameFromUrl = (url: string) => {
  const params = new URL(url).searchParams
  const contentDisposition = params.get('response-content-disposition')
  if (contentDisposition) {
    const filenamePart = contentDisposition.split(';').find(part => part.trim().startsWith('filename'))
    if (filenamePart) {
      return decodeURIComponent(filenamePart.split('=')[1].replace(/['"]/g, ''))
    }
  }
  return ''
}
