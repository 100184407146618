import Box from "@/common/components/Box";
import ListHeader from "@/common/components/ListHeader";
import TextField from "@/common/components/TextField";
import { FileBox, FileSearch, Info } from "lucide-react";
import { useCardCurrentLimits } from "../../hooks/useCardCurrentLimits";
import { useProducts } from "@/common/hooks/queries/useProducts";
import { Mapper } from "@/common/services/mapper";
import { ProductData } from "@/common/types/product.type";
import useDebounce from "@/common/hooks/useDebounce";
import Select from "@/common/components/Select";
import { formatCurrency } from "@/common/utils/formatters";
import { useCardDetails } from "../../../../common/hooks/queries/useCardDetails";
import Badge from "@/common/components/Badge";
import EquipmentInfo from "@/features/consumption/components/EquipmentInfo";
import { useState } from "react";
import { Option } from "@/common/types/option";
import MaskedTextField from "@/common/components/MaskedTextField";
import { customUnitMask } from "@/common/constants/masks.constant";
import { options as unitForQuantity } from '@/common/constants/unitForQuantityOptions.contant';

const CardCurrentLimitsShow = () => {
  const [cardIdentifier, setCardIdentifier] = useDebounce('');
  const [product, setProduct] = useState<ProductData>();

  const [searchProduct, setSearchProduct] = useDebounce('');

  const { data: cardDetails, isPending: cardDetailsIsPending, isError: cardDetailsIsError } = useCardDetails(cardIdentifier);

  const { data: cardCurrentLimits, isPending: cardCurrentLimitsIsPending } = useCardCurrentLimits(cardIdentifier, product?.id);

  const { data: products } = useProducts<ProductData[]>({
    filters: { search: searchProduct }
  });

  return (
    <div className="flex flex-col gap-6">
      <div className="flex">
        <ListHeader
          Icon={<FileSearch className="w-10 h-10" strokeWidth={1.5} />}
          title="Consulta"
        />
      </div>

      <Box className="h-[calc((100vh-104px-1.5rem))] overflow-hidden flex gap-6">
        <div className="grow w-1/2 flex flex-col gap-6">
          <p className="text-[20px] font-bold">Confirmação de segurança</p>

          <TextField
            label="Código do cartão ou Placa/N. de série do equipamento"
            onChange={(e: any) => setCardIdentifier(e.target.value)}
            markAsRequired
            errorMessage={cardDetailsIsError ? 'Cartão não encontrado' : ' '}
            isLoading={!!(cardIdentifier && cardDetailsIsPending)}
          />

          <Select
            label="Produto para consulta"
            markAsRequired
            onInputChange={setSearchProduct}
            options={Mapper.mapToOptions<ProductData>({ valueFieldName: 'id', labelFieldName: 'display_name' })(products)}
            onChange={(e) => setProduct(products.find((p) => p.id === (e as Option).value))}
          />

          {cardDetails && (
            <EquipmentInfo cardDetails={cardDetails} />
          )}
        </div>

        <div className="grow w-1/2 flex flex-col gap-6 overflow-y-auto pr-2">
          {product && cardDetails && (
            <>
              {!cardCurrentLimitsIsPending && cardCurrentLimits ? (
                <Box className="flex flex-col gap-2">
                  <p>Valores liberados</p>

                  <div className="flex gap-2">
                    <div>
                      <Info strokeWidth={1} color="var(--warning)" />
                    </div>

                    <div className="flex flex-col gap-2">
                      <p className="text-[12px]">Os valores exibidos são calculados conforme todas as limitações e créditos garantidos a filial.</p>
                      <p className="text-[12px] font-light">Caso existam ambos Quantidade e Valor total, vale o que for atingido primeiro.</p>
                    </div>
                  </div>

                  <Box className="flex flex-col gap-3">
                    <Badge
                      Icon={() => <FileBox strokeWidth={1} />}
                      text={product?.display_name}
                    />

                    <div className="flex items-center gap-2">
                      {cardCurrentLimits?.quantity_limit_for_display !== null && (
                        <div className="grow">
                          <MaskedTextField
                            label="Quantidade"
                            mask={customUnitMask(
                              unitForQuantity.find((unit) => unit.value === product.unit_for_quantity)?.symbol || '',
                            )}
                            value={cardCurrentLimits?.quantity_limit_for_display?.toString()}
                            disabled
                          />
                        </div>
                      )}

                      {cardCurrentLimits?.quantity_limit_for_display !== null && cardCurrentLimits?.credit_limit_for_display !== null && (
                        <div>
                          <p className="mt-4">Ou</p>
                        </div>
                      )}

                      {cardCurrentLimits?.credit_limit_for_display !== null && (
                        <div className="grow">
                          <TextField
                            label="Valor total"
                            value={formatCurrency(Number(cardCurrentLimits?.credit_limit_for_display))}
                            disabled
                          />
                        </div>
                      )}
                    </div>
                  </Box>
                </Box>
              ) : (cardCurrentLimitsIsPending) ? (
                <Box className="h-[280px] flex items-center justify-center">
                  <div className="w-6 h-6 border-4 border-dashed rounded-full animate-spin border-blue-800 mb-1 mr-1" />
                </Box>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </Box>
    </div>
  );
};

export default CardCurrentLimitsShow;