import Modal from '@/common/components/Modal'
import { ConsumptionData } from '../types/consumption.type'

interface ModalSuccessProps {
  data: ConsumptionData
  setData: React.Dispatch<React.SetStateAction<boolean>>
  clearForm: () => void
}

const ModalSuccess = ({ data, setData, clearForm }: ModalSuccessProps) => {
  const closeModalAndClearForm = () => {
    setData(false)
    clearForm()
  }

  return (
    <Modal
      isOpen={!!data}
      setIsOpen={closeModalAndClearForm}
      onConfirm={closeModalAndClearForm}
      Trigger={<></>}
      footer="Entendido!"
      title="Transação bem sucedida!"
      body={
        <div className="flex flex-col gap-2 py-2">
          <div>
            <p className="text-[14px] font-light">ID da requisição</p>
            <p className="text-[16px] text-2xl text-muted">{data.id}</p>
          </div>
          <div>
            <p className="text-[14px] font-light">Número de protocolo</p>
            <p className="text-[16px] text-2xl text-muted">{data.protocol_number}</p>
          </div>
        </div>
      }
    />
  )
}

export default ModalSuccess
