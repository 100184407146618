import Accordion from "@/common/components/Accordion";
import TextField from "@/common/components/TextField";
import { formatCurrency, formatPercentage } from "@/common/utils/formatters";
import { ContractData } from "../../types/contract.type";

interface ContractFeesSectionProps {
  contract?: ContractData;
}

const FeesSection = ({ contract }: ContractFeesSectionProps) => {
  return (
    <Accordion
      head={
        <p>Regras e Tarifas</p>
      }
      body={
        <div className="flex flex-col gap-4">
          {contract?.contract_fees?.map(({ fee_template, value }) => (
            <div key={fee_template.id} className="flex gap-2">
              <TextField
                label="Tarifa"
                defaultValue={fee_template.display_name}
                className="w-4/5"
                disabled
              />

              <TextField
                label="Valor"
                defaultValue={fee_template.calculate_as === 'percentage' ? formatPercentage(value) : formatCurrency(value)}
                className="grow"
                disabled
              />
            </div>
          ))}
        </div>
      }
    />
  );
};

export default FeesSection;
