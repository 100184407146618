import usePrivateAxios from "@/common/api/usePrivateAxios";
import { unFormatCurrency } from "@/common/utils/unFormatters";
import { useMutation } from "@tanstack/react-query";
import { ProductPriceValidationSchema } from "../schemas/productPrice.schema";
import { AxiosError, AxiosResponse } from "axios";

interface useCreateProductPriceProps {
  id: string;
  onSuccess: (response: AxiosResponse) => void;
  onError: (error: AxiosError) => void;
}

const useUpdateProductPrice = ({ id, onSuccess, onError }: useCreateProductPriceProps) => {
  const privateAxios = usePrivateAxios();

  return useMutation({
    mutationKey: ['updateProductPrice'],
    mutationFn: async (form: ProductPriceValidationSchema) => {
      const payload = {
        value: form?.value && unFormatCurrency(form?.value),
      }

      return privateAxios.put(`/product_prices/${id}`, payload);
    },
    onSuccess,
    onError,
  });
}

export default useUpdateProductPrice;
