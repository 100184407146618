import Button from "@/common/components/Button";
import { ImageCarousel } from "@/common/components/ImageCarousel";
import Select from "@/common/components/Select";
import { useNavigate } from "react-router-dom";
import { CompanyData } from "../../types/company.type";

interface AccreditationInformationSectionProps {
  matrixBranch?: CompanyData["matrix_branch"];
  productCategories?: CompanyData["product_categories"];
}

export const AccreditationInformationSection = ({ matrixBranch, productCategories }: AccreditationInformationSectionProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <p className="text-[18px] font-bold">
          Dados de credenciamento
        </p>

        <Button
          type="button"
          onClick={() => navigate("/company/mine/contract")}
        >
          Ver contrato
        </Button>
      </div>

      {matrixBranch?.photos && matrixBranch.photos.length > 0 && (
        <ImageCarousel
          files={matrixBranch.photos.map((photo: any) => ({ id: photo.id, file: photo.url }))}
        />
      )}

      <Select
        label="Serviços"
        placeholder
        value={productCategories?.map(({ id, display_name }) => ({
          value: id,
          label: display_name,
        }))}
        isMulti
        isDisabled
      />

      <Select
        label="Meios de captura"
        placeholder
        value={matrixBranch?.capture_methods.map(({ capture_method }) => ({
          value: capture_method.id,
          label: capture_method.display_name,
        }))}
        isMulti
        isDisabled
      />
    </div>
  );
};
