import usePrivateAxios from "@/common/api/usePrivateAxios";
import { unFormatCurrency } from "@/common/utils/unFormatters";
import { useMutation } from "@tanstack/react-query";
import { ProductPriceValidationSchema } from "../schemas/productPrice.schema";
import { AxiosError, AxiosResponse } from "axios";

interface useCreateProductPriceProps {
  onSuccess: (response: AxiosResponse) => void;
  onError: (error: AxiosError) => void;
}

const useCreateProductPrice = ({ onSuccess, onError }: useCreateProductPriceProps) => {
  const privateAxios = usePrivateAxios();

  return useMutation({
    mutationKey: ['createProductPrice'],
    mutationFn: async (form: ProductPriceValidationSchema) => {
      const payload = {
        product_id: form?.product?.value || null,
        value: form?.value && unFormatCurrency(form?.value),
      }

      return privateAxios.post('/product_prices', payload);
    },
    onSuccess,
    onError,
  });
}

export default useCreateProductPrice;
