import Modal from '@/common/components/Modal';
import { BillingTransactionIdentifierErrorResponseData } from '@/common/types/billing/transactionIdentifier.type';
import { Dispatch, SetStateAction } from 'react';

interface ModalErrorProps {
  data: BillingTransactionIdentifierErrorResponseData;
  setData: Dispatch<SetStateAction<boolean | BillingTransactionIdentifierErrorResponseData>>;
}

const ModalError = ({ data, setData }: ModalErrorProps) => {
  return (
    <Modal
      isOpen={!!data}
      setIsOpen={setData}
      onConfirm={() => setData(false)}
      Trigger={<></>}
      footer="Entendido!"
      title="Estorno não concluído"
      titleClass="text-danger-soft"
      body={
        <div className="flex flex-col gap-4 py-2">
          <div className="flex flex-col gap-2 py-2">
            <p className="text-[16px]">Algo deu errado e seu estorno não foi concluído.</p>
          </div>

          <div>
            <p className="text-[14px] font-light">Código da requisição</p>
            <p className="text-[16px] text-2xl text-muted">{data?.response?.data?.request_id}</p>
          </div>

          <div>
            <p className="text-[14px] font-light">Erros</p>

            {data?.response?.data?.errors?.base?.map((message, index) => (
              <p key={index} className="text-[16px] text-2xl text-muted">
                - {message}
              </p>
            ))}
          </div>
        </div>
      }
    />
  );
};

export default ModalError;
