import { ImageWithPlaceholder } from '@/common/components/ImageWithPlaceholder'
import { TicketData } from '@/common/types/support/ticket.type'
import { UserRoundIcon } from 'lucide-react'

type SupportTicketCreatorProps = {
  creator: TicketData['creator']
}

export const SupportTicketCreator = ({ creator }: SupportTicketCreatorProps) => {
  return (
    <div className="flex gap-2 items-center">
      <ImageWithPlaceholder
        url={creator?.profile_picture?.url}
        alt={creator?.full_name}
        placeholderIcon={UserRoundIcon}
        className="size-6"
        iconClassName="size-4"
      />

      <p className="text-xs">{creator?.full_name}</p>
    </div>
  )
}
