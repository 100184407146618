import { Image, Upload } from "lucide-react";
import { forwardRef } from "react"

export interface ImageUploaderProps extends React.HTMLAttributes<HTMLInputElement> {
  label: string,
  name: string,
  currentImageUrl?: string,
  isLoading: boolean,
  accept?: string,
  disabled?: boolean
}

export const ImageUploader = forwardRef<HTMLInputElement, ImageUploaderProps>(({
  label,
  name,
  currentImageUrl,
  isLoading,
  disabled,
  ...props
}, ref) => {
  return (
    <div className="flex flex-col items-start">
      <p className="text-xs mb-1">{label}</p>
      
      {currentImageUrl
        ? (
          <label className="relative flex flex-col justify-center items-center cursor-pointer" htmlFor="file_input" >
            <img src={currentImageUrl} className="object-cover rounded-full w-[80px] h-[80px] hover:bg-white-100" />
            <div className="opacity-0 backdrop-blur-[4px] absolute inset-[-1px] bg-black-600 flex justify-center items-center rounded-full hover:opacity-100">
              <Upload /> 
            </div>
          </label>
        )
        : (
          <>
            <label className={`flex flex-col justify-center w-[80px] h-[80px] items-center bg-neutral-1 ${!disabled ? 'cursor-pointer' : ''}`} htmlFor="file_input" >
              <div className="border border-neutral-3 rounded-full bg-neutral-2 w-full h-full flex justify-center items-center">
              {
                isLoading
                ? <div className="w-4 h-4 border-4 border-dashed rounded-full animate-spin border-blue-800"></div>
                : disabled ? <Image /> : <Upload />
              }
              </div>
            </label>
          </>
        )
      }
      
      <input type="file" ref={ref} name={name} className="hidden" id="file_input" disabled={disabled} {...props} />
      
    </div>
  )
})