import { DownloadIcon, Trash2Icon } from "lucide-react"
import { twMerge } from "tailwind-merge"

interface DocumentFileListProps {
  files: {
    fileName: string,
    currentFileUrl: string,
  }[],
  handleRemoveFile?: (index: number) => void,
  className?: string,
}

export const DocumentFileList = ({
  files,
  handleRemoveFile,
  className,
}: DocumentFileListProps) => {
  return (
    <div className="flex flex-wrap gap-4">
      {files.map((file, index) => (
        <div key={index} className="relative flex flex-col items-start">
          <div className={twMerge("flex flex-col justify-between p-4 w-[180px] h-[100px] bg-white-200 rounded-lg", className)}>
            <div className="w-full flex justify-start max-w-[100px] overflow-hidden">
              <p className="italic text-xs">{file.fileName || 'Documento'}</p>
            </div>
            <div className="w-full flex justify-end gap-4">
              {!!handleRemoveFile && (
                <button type="button" onClick={() => handleRemoveFile(index)}>
                  <Trash2Icon strokeWidth={1} />
                </button>
              )}
              <a href={file.currentFileUrl} download={file.fileName} target="_blank">
                <DownloadIcon strokeWidth={1} />
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
