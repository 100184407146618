import { privateAxios } from '@/common/api/axios'
import { defaultTransformerResponse } from '@/common/constants/defaultTransformerResponse.constant'
import { Mapper } from '@/common/services/mapper'
import { MetaResponse } from '@/common/types/metaResponse'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

type UseEstablishmentUsersProps<T> = {
  filters?: {
    search?: string
    page?: number
    role?: string
    status?: string
    year?: string
  }
  mapper?: (data: any) => T
}

export const useEstablishmentUsers = <T,>({
  filters: { search = '', page, role, status, year } = {},
  mapper = Mapper.default<T>(),
}: UseEstablishmentUsersProps<T>) => {
  const { data: { data, meta } = defaultTransformerResponse<T>(), ...rest } =
    useQuery({
      queryKey: ['establishmentUsers', page, search, role, status, year],
      queryFn: () => {
        return privateAxios.get('/users', {
          params: {
            search,
            page,
            'q[roles_id_eq]': role,
            'q[status_eq]': status,
          },
        })
      },
      select: (data: { data: { data: any[]; meta: MetaResponse } }) => {
        return {
          data: mapper(data?.data?.data),
          meta: data?.data?.meta,
        }
      },
      placeholderData: keepPreviousData,
    })

  return { data, meta, ...rest }
}
