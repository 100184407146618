import usePrivateAxios from '@/common/api/usePrivateAxios'
import {
  EstablishmentUserData,
  EstablishmentUserResponse,
} from '@/features/users/types/establishmentUsers'
import { UseQueryResult, useQuery } from '@tanstack/react-query'

interface UseEstablishmentUserProps {
  id: string
}

export const useEstablishmentUser: (
  props: UseEstablishmentUserProps,
) => UseQueryResult<EstablishmentUserData, unknown> = ({ id }) => {
  const privateAxios = usePrivateAxios()

  return useQuery<EstablishmentUserResponse, unknown, EstablishmentUserData>({
    enabled: !!id,
    queryKey: ['establishmentUser', id],
    queryFn: () => {
      return privateAxios.get(`/users/${id}`)
    },
    select: (data) => data?.data?.data,
  })
}
