import Button from '@/common/components/Button'
import { formatCurrency } from '@/common/utils/formatters'
import { useFormContext } from 'react-hook-form'
import { ConsumptionValidationSchema } from '../schemas/create.schema'
import EssentialInformationForm from './EssentialInformationForm'
import ProductsForm from './ProductsForm'

interface TabInformationProps {
  activeTab: number
  handleTabChange: (tabIndex: number) => void
  clearForm: () => void
}

const TabInformation = ({ activeTab, handleTabChange, clearForm }: TabInformationProps) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<ConsumptionValidationSchema>()

  const watchTotalAmount = watch('total_amount')

  return (
    <>
      <div className="flex gap-6 grow max-h-[calc(100vh-192px)] overflow-y-auto">
        <div className="basis-1/2 h-full">
          <EssentialInformationForm
            register={register}
            clearForm={clearForm}
            setValue={setValue}
            watch={watch}
            errors={errors}
          />
        </div>

        <div className="basis-1/2 h-full">
          <ProductsForm />
        </div>
      </div>

      <div className="flex items-center justify-end gap-4">
        <div>
          <p className="text-[14px] font-light italic">Valor total</p>

          <p className="text-[18px] font-bold">{formatCurrency(watchTotalAmount || 0)}</p>
        </div>

        <div>
          <Button type="button" onClick={() => handleTabChange(activeTab + 1)}>
            Ver resumo
          </Button>
        </div>
      </div>
    </>
  )
}

export default TabInformation
