
import GeneralInformationTab from "../pages/Tabs/GeneralInformation";
import EventsTab from "../pages/Tabs/Events";
import LogRequestsTable from "@/common/components/LogRequestsTable";

export const userTabs = (userId: string) => [
  {
    label: 'Dados gerais',
    value: '',
    body: <GeneralInformationTab />,
  },
  {
    label: 'Histórico de vendas',
    value: 'events',
    body: <EventsTab />,
  },
  {
    label: 'Histórico de ações',
    value: 'actionsHistory',
    body: <LogRequestsTable type="EstablishmentUser" id={userId} />,
  },
];
