import { privateAxios } from '@/common/api/axios'
import { unFormatCnpj, unFormatCurrency, unFormatCnpj as unFormatMask, unFormatUnknownUnit } from '@/common/utils/unFormatters'
import { useMutation } from '@tanstack/react-query'
import { ConsumptionValidationSchema } from '../schemas/create.schema'

interface useCreateConsumptionProps {
  onSuccess: (response: any) => void
  onError: (response: any) => void
}

const useCreateConsumption = ({ onSuccess, onError }: useCreateConsumptionProps) => {
  const { mutate, isPending } = useMutation({
    mutationKey: ['createConsumption'],
    mutationFn: async (form: ConsumptionValidationSchema) => {
      const payload = {
        ...form,
        buyer_cpf: form.buyer_cpf ? unFormatCnpj(form.buyer_cpf) : '',
        line_items_attributes: form.line_items_attributes.map(({ product_id, quantity, amount }) => ({
          product_id,
          quantity: unFormatUnknownUnit(quantity),
          amount: amount && Number(unFormatCurrency(amount)),
        })),
        current_equipment_usage: form.current_equipment_usage && Number(unFormatMask(form.current_equipment_usage)),
      }

      return privateAxios.post('/client/consumptions', {
        consumption: payload,
      })
    },
    onSuccess,
    onError,
  })

  return { mutate, isPending }
}

export default useCreateConsumption
