import usePrivateAxios from "@/common/api/usePrivateAxios";
import { useMutation, UseMutationResult } from "@tanstack/react-query"
import { AxiosResponse } from "axios"

type UseBlobsProps = {
  file: File
  filename: string
}

export type UseBlobs = () => UseMutationResult<AxiosResponse<any, any>, unknown, UseBlobsProps>

const useBlobs: UseBlobs = () => {
  const privateAxios = usePrivateAxios();

  return useMutation({
    mutationKey: ['blobs'],
    mutationFn: async ({ file, filename }: UseBlobsProps) => {
      return await privateAxios.postForm('/blobs', {
        blob: {
          io: file,
          filename,
        }
      })
    },
  })
}

export default useBlobs
