import { forwardRef, useEffect, useRef, useState } from 'react';
import TextField, { TextFieldProps } from '../TextField';

export interface UnitTextFieldProps extends Omit<TextFieldProps, 'type'> {
  unit?: string;
  decimals?: number;
  maxValue?: number;
  defaultValue?: string | number;
}

export const UnitTextField = forwardRef<HTMLInputElement, UnitTextFieldProps>(({
  unit = '',
  decimals = 3,
  value = '',
  onChange,
  maxValue = 999_999.999,
  defaultValue, 
  ...props
}, ref) => {
  const [internalValue, setInternalValue] = useState<string>(defaultValue ? String(defaultValue) : '');
  const inputRef = useRef<HTMLInputElement | null>(null);

  const formatWithThousandSeparator = (number: number) => {
    return number
      .toFixed(decimals)
      .replace('.', ',')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  const formatDefaultValue = (value: string | number) => {
    if (typeof value === 'string') {
      const numericValue = Number(value.replace(/\D/g, '')) / 10 ** decimals;

      return `${formatWithThousandSeparator(numericValue)} ${unit}`;
    }

    if (typeof value === 'number') {
      return `${formatWithThousandSeparator(value)} ${unit}`;
    }

    return `0${decimals > 0 ? ',' : ''}${''.padEnd(decimals, '0')} ${unit}`;
  };

  const handleChange = (e: any) => {
    let inputValue = e.target.value.replace(/\D/g, '');

    if (unit && e.nativeEvent.inputType === 'deleteContentBackward') {
      inputValue = inputValue.slice(0, -1);
    }

    let numericValue = Number(inputValue) / 10 ** decimals;

    if (numericValue === 0) {
      setInternalValue('');

      if (onChange) {
        e.target.value = '';
        onChange(e);
      }

      return;
    }

    if (numericValue < 0) {
      numericValue = 0;
    }

    if (maxValue !== undefined && numericValue > maxValue) {
      return;
    }

    const formattedValue = `${formatWithThousandSeparator(numericValue)} ${unit}`;

    setInternalValue(formattedValue);

    if (onChange) {
      e.target.value = formattedValue;
      onChange(e);
    }

    if (inputRef.current) {
      const inputLength = formattedValue.length;
      inputRef.current.setSelectionRange(inputLength, inputLength);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      const formattedValue = formatDefaultValue(defaultValue);
      setInternalValue(formattedValue);
    }
  }, [defaultValue]);

  return (
    <TextField
      ref={(el) => {
        inputRef.current = el;

        if (typeof ref === 'function') {
          return ref(el);
        }

        if (ref) {
          (ref as React.MutableRefObject<HTMLInputElement | null>).current = el;
        }
      }}
      value={internalValue || value}
      onChange={handleChange}
      placeholder={`0${decimals > 0 ? ',' : ''}${''.padEnd(decimals, '0')} ${unit}`}
      {...props}
    />
  );
},
);
