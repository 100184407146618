import usePrivateAxios from '@/common/api/usePrivateAxios'
import { transformNewRoles } from '@/common/utils/roleTransformers'
import { removeSpecialCharacters } from '@/common/utils/unFormatters'
import { EstablishmentUserValidationSchema } from '@/features/users/schemas/create.schema'
import { useMutation } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'

interface UseCreateEstablishmentUserProps {
  onSuccess: (response: AxiosResponse) => void
  onError: (error: AxiosError) => void
}

export const useCreateEstablishmentUser = ({
  onSuccess,
  onError,
}: UseCreateEstablishmentUserProps) => {
  const privateAxios = usePrivateAxios()

  return useMutation({
    mutationKey: ['createEstablishmentUser'],
    mutationFn: async (form: EstablishmentUserValidationSchema) => {
      const payload = {
        ...form,
        cpf: removeSpecialCharacters(form.cpf),
        date_of_birth: form.date_of_birth || null,
        user_roles_attributes: form?.user_roles?.length
          ? form.user_roles.map(transformNewRoles)
          : [],
        status: form?.status?.value,
        phone_attributes: {
          number: form?.phone?.number ? removeSpecialCharacters(form.phone.number) : null,
        },
        email: form.email || null,
      }

      return privateAxios.postForm('/users', { establishment_user: payload })
    },
    onSuccess,
    onError,
  })
}
