import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import PrivateRoute from './common/components/PrivateRoute';
import Layout from './common/Layout';
import * as Sentry from '@sentry/react';
import { privateCompanyRoutes, publicCompanyRoutes, publicRoutes } from './routes';
import PublicCompanyRoute from './common/components/PublicCompanyRoute';
import { useEffect } from 'react';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  useEffect(() => {
    const loadingTcard = document.getElementById('loading-tcard')!;

    setTimeout(() => {
      loadingTcard.classList.add('loading-tcard_slide-top');

      setTimeout(() => {
        loadingTcard.remove();
      }, 500);
    }, 1200);
  }, []);

  return (
    <div>
      <BrowserRouter>
        <SentryRoutes>
          {publicRoutes.map((route) => (
            <Route path={route.path} Component={route.comp} key={route.path} />
          ))}

          <Route element={<PublicCompanyRoute />}>
            {publicCompanyRoutes.map((route) => (
              <Route path={route.path} Component={route.comp} key={route.path} />
            ))}

            <Route element={<Layout />}>
              <Route element={<PrivateRoute />}>
                {privateCompanyRoutes.map((route) => (
                  <Route path={route.path} Component={route.comp} key={route.path} />
                ))}
              </Route>
            </Route>
          </Route>
        </SentryRoutes>
      </BrowserRouter>
    </div>
  );
}

export default App;
